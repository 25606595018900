import { useUser } from '@web-ui-root/composables/user';
import DateTimeWrapper, {
  HH_MM,
  D_MMM_HH_MM,
  D_MMM,
  DD_MM_YY_HH_MM,
  DD_MM_YY,
} from './date-time-wrapper';

function humanDate(value: string | Date | null | undefined, long = true): string | null {
  const { locale } = useUser();
  if (value === null || value === undefined) {
    return null;
  }
  const today = DateTimeWrapper.now();
  const dateTimeValue = DateTimeWrapper.fromJSDate(new Date(String(value))).setLocale(locale.value);
  const isToday = dateTimeValue.hasSame(today, 'day');
  const isSameYear = dateTimeValue.hasSame(today, 'year');
  let format;
  if (isToday) {
    format = HH_MM;
  } else if (isSameYear) {
    if (long) {
      format = D_MMM_HH_MM;
    } else {
      format = D_MMM;
    }
  } else if (long) {
    format = DD_MM_YY_HH_MM;
  } else {
    format = DD_MM_YY;
  }
  return dateTimeValue.toFormat(format);
}

/**
 * Same as human date, but safer since the argument cannot be null
 */
function safeHumanDate(date: Date | string, long = true): string {
  const result = humanDate(date, long);

  if (result !== null) {
    return result;
  }

  // unreachable, breadcrumb
  console.log(date);
  throw new Error('InvariantError: a null date was returned by the formatter');
}

export { humanDate, safeHumanDate };
