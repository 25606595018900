import maplibregl, {
  GeoJSONSource,
  LngLatBounds,
  type MapDataEvent,
  type MapSourceDataEvent,
  type MapStyleDataEvent,
  type Source,
} from 'maplibre-gl';
import type { BBox } from 'geojson';
import { type Ref, type ShallowRef, ref, shallowRef, type UnwrapRef, watchEffect } from 'vue';

import { API_URL } from '@web-ui-root/helpers/vite';
import {
  type LayerEventHandler,
  useLayerEventHandler,
} from '@web-ui-root/helpers/map/maplibre-layer-event-handler';
import { useSDK } from '@web-ui-root/composables/sdk';
import Invariant from '@web-ui-root/helpers/invariant';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@web-ui-root/helpers/local-storage/local-storage';
import Throttle from '@web-ui-root/helpers/throttle';
import type { Geometry, LineString, MultiLineString, Point } from './edit/types';

/**
 * Modes that are available initially without a specific state:
 * - viewDetailsLeftPanel [0] = click to view details in lhs panel
 * - addPinGroup [1] = add pinGroup (going to 0)
 * - addEdge [2] = add edge (going to 0)
 * - clickEdit [5] = click to edit (going to 3 or 4 or reverting to 0)
 */
export const initialModes = [
  'viewDetailsLeftPanel',
  'addPinGroup',
  'addPinGroupFromCoords',
  'addEdge',
  'clickEdit',
] as const;

export type InitialModes = (typeof initialModes)[number];

// @see https://github.com/maplibre/maplibre-gl-js/issues/785#issuecomment-1066511246
export const isGeoJsonSource = (source?: Source): source is GeoJSONSource =>
  source?.type === 'geojson';

export function isMapSourceDataEvent(e: MapDataEvent): e is MapSourceDataEvent {
  return (
    typeof e === 'object' &&
    e !== null &&
    'type' in e &&
    e.type === 'source' &&
    'dataType' in e &&
    e.dataType === 'source'
  );
}

export function isMapSourceGeojsonEvent(data: any): data is MapSourceDataEvent {
  return (
    typeof data === 'object' &&
    data !== null &&
    'type' in data &&
    data.type === 'source' &&
    'dataType' in data &&
    data.dataType === 'geojson'
  );
}

export function isMapStyleDataEvent(data: any): data is MapStyleDataEvent {
  return typeof data === 'object' && data !== null && 'type' in data && data.type === 'style';
}

export function isPointGeometry(geometry: Geometry | GeoJSON.Geometry): geometry is Point {
  return geometry.type === 'Point';
}

export function isLineStringGeometry(
  geometry: Geometry | GeoJSON.Geometry,
): geometry is LineString {
  return geometry.type === 'LineString';
}

export function isMultiLineStringGeometry(
  geometry: Geometry | GeoJSON.Geometry,
): geometry is MultiLineString {
  return geometry.type === 'MultiLineString';
}

export function convertBBoxToLngLatBounds(bbox: BBox): LngLatBounds {
  const [minX, minY, maxX, maxY] = bbox;
  return new LngLatBounds([minX, minY], [maxX, maxY]);
}

export function isMode(query: string): query is InitialModes {
  return initialModes.some((m) => m === query);
}
/**
 * Modes that require a specific state:
 * - editPinGroup [3] = edit pinGroup (going to 5 or reverting to 0)
 * - editEdge [4] = edit edge (going to 5 or reverting to 0)
 * - grid [6] = shows only pin groups of currently selected pin group grid
 */
const stateModes = ['editPinGroup', 'editEdge', 'grid'] as const;

/**
 * All modes:
 * - viewDetailsLeftPanel [0] = click to view details in lhs panel
 * - addPinGroup [1] = add pinGroup (going to 0)
 * - addEdge [2] = add edge (going to 0)
 * - editPinGroup [3] = edit pinGroup (going to 5 or reverting to 0)
 * - editEdge [4] = edit edge (going to 5 or reverting to 0)
 * - clickEdit [5] = click to edit (going to 3 or 4 or reverting to 0)
 * - grid [6] = shows only pin groups of currently selected pin group grid
 */
const modes = [...initialModes, ...stateModes];

type Mode = (typeof modes)[number];

type MapState = {
  mode: Ref<Mode>;
  editingGraphSaving: Ref<boolean>;
};

const state: MapState = {
  mode: ref('viewDetailsLeftPanel'),
  editingGraphSaving: ref(false),
};

type MapModeModel = {
  mode: Ref<Mode>;
  /**
   * A boolean to set the saving state for a saving animation
   */
  editingGraphSaving: Ref<boolean>;
};

export function useMapMode(): MapModeModel {
  return {
    mode: state.mode,
    editingGraphSaving: state.editingGraphSaving,
  };
}

const map: ShallowRef<maplibregl.Map | undefined> = shallowRef();
const mapEventHandler: ShallowRef<LayerEventHandler | undefined> = shallowRef();

export async function initMap(el: HTMLElement): Promise<void> {
  const { sdk } = useSDK();

  if (map.value === undefined) {
    map.value = new maplibregl.Map({
      container: el,
      attributionControl: false,
      style: {
        version: 8,
        sources: {},
        glyphs: 'glyph://{fontstack}/{range}',
        layers: [],
      },
      minZoom: 1,
      maxZoom: 24,
      renderWorldCopies: false,
      maxBounds: [
        [-179.0, -89.0],
        [179.0, 89.0],
      ],
      dragRotate: false,
      transformRequest: (url) => {
        if (url.startsWith(API_URL)) {
          return {
            url,
            headers: sdk.headers,
            credentials: 'include',
          };
        }
        const glyphPrefix = 'glyph://';
        const glyphPostFix = '/{range}';
        if (url.startsWith(glyphPrefix)) {
          const cleanedUrl = url.substring(glyphPrefix.length, url.length - glyphPostFix.length);
          return { url: cleanedUrl };
        }
        return { url };
      },
    });
  }

  mapEventHandler.value = useLayerEventHandler(map.value);
}

type MapModel = {
  map: ShallowRef<maplibregl.Map | undefined>;
  mapEventHandler: ShallowRef<LayerEventHandler | undefined>;
};

export function useMap(): MapModel {
  return {
    map,
    mapEventHandler,
  };
}

// just in case it's needed smwhere else
const filterKeys = [
  'location',
  'info',
  'critical',
  'serious',
  'ok',
  'noMeasurement',
  'installedDevice',
  'connectedDevice',
] as const;
type FilterKey = (typeof filterKeys)[number];
type MapFiltersModel = Ref<Record<FilterKey, boolean>>;

function assertLSFilters(LSFilters: any): LSFilters is UnwrapRef<MapFiltersModel> | null {
  if (LSFilters === null) {
    return true;
  }
  Invariant.assert('location' in LSFilters, 'location key is missing in mapFilters');
  Invariant.assert('info' in LSFilters, 'critical key is missing in mapFilters');
  Invariant.assert('critical' in LSFilters, 'critical key is missing in mapFilters');
  Invariant.assert('serious' in LSFilters, 'serious key is missing in mapFilters');
  Invariant.assert('ok' in LSFilters, 'ok key is missing in mapFilters');
  Invariant.assert('noMeasurement' in LSFilters, 'noMeasurement key is missing in mapFilters');
  Invariant.assert('installedDevice' in LSFilters, 'installedDevice key is missing in mapFilters');
  Invariant.assert('connectedDevice' in LSFilters, 'connectedDevice key is missing in mapFilters');
  Invariant.assert(
    typeof LSFilters.location === 'boolean',
    'location mapFilter value is not a boolean',
  );
  Invariant.assert(typeof LSFilters.info === 'boolean', 'info mapFilter value is not a boolean');
  Invariant.assert(
    typeof LSFilters.critical === 'boolean',
    'critical mapFilter value is not a boolean',
  );
  Invariant.assert(
    typeof LSFilters.critical === 'boolean',
    'critical mapFilter value is not a boolean',
  );
  Invariant.assert(
    typeof LSFilters.serious === 'boolean',
    'serious mapFilter value is not a boolean',
  );
  Invariant.assert(typeof LSFilters.ok === 'boolean', 'ok mapFilter value is not a boolean');
  Invariant.assert(
    typeof LSFilters.noMeasurement === 'boolean',
    'noMeasurement mapFilter value is not a boolean',
  );
  Invariant.assert(
    typeof LSFilters.installedDevice === 'boolean',
    'installedDevice mapFilter value is not a boolean',
  );
  Invariant.assert(
    typeof LSFilters.connectedDevice === 'boolean',
    'connectedDevice mapFilter value is not a boolean',
  );

  return true;
}

const values = Object.values as <T>(obj: T) => Array<T[keyof T]>;

function getFiltersFromLocalStorage(): Record<FilterKey, boolean> {
  const lsFilters = getLocalStorageItem('mapFilters', true);

  if (lsFilters !== null) {
    // add the missing variables in case the user has some filters,
    // but is missing some of the new ones
    if (!('info' in lsFilters)) {
      lsFilters.info = false;
    }
    if (!('connectedDevice' in lsFilters)) {
      lsFilters.connectedDevice = false;
    }
    if ('location' in lsFilters && typeof lsFilters.location !== 'boolean') {
      lsFilters.location = false;
    }
    if (!('installedDevice' in lsFilters)) {
      lsFilters.installedDevice = false;
    }
    setLocalStorageItem('mapFilters', lsFilters, true);
  }
  assertLSFilters(lsFilters);
  return lsFilters;
}

export function useMapFilters(): MapFiltersModel {
  const throttle = new Throttle(2000);

  const filters: MapFiltersModel = ref({
    location: false,
    info: false,
    critical: false,
    serious: false,
    ok: false,
    noMeasurement: false,
    installedDevice: false,
    connectedDevice: false,
  });

  const lsFilters = getFiltersFromLocalStorage();

  if (lsFilters !== null) {
    filters.value = lsFilters;
  }

  watchEffect(() => {
    const allFalse = values(filters.value).every((f) => !f);

    throttle.run(() => {
      // making sure the local storage is still valid
      getFiltersFromLocalStorage();

      if (filters.value !== null && !allFalse) {
        setLocalStorageItem('mapFilters', filters.value, true);
      } else {
        removeLocalStorageItem('mapFilters');
      }
    });
  });

  return filters;
}
