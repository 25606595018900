import {
  type OnThemeChangeCallback,
  type Theme,
  type Themes,
  type Colors,
  type ColorKeys,
} from './types';
import { HEX_COLOR_REGEXP } from './colors';

/* eslint-disable no-empty-function */
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop: OnThemeChangeCallback = () => {};
/* eslint-enable no-empty-function */

// I know that Singletons in TS are a bit anti-pattern,
// but i can't achieve the same result with a namespace
export class ThemeManager {
  // eslint-disable-next-line no-use-before-define
  static #instance: ThemeManager;

  #theme: Theme;

  #variant: keyof Colors = 'light'; // maybe tweakable later

  #onThemeChangeCb: OnThemeChangeCallback = noop;

  private constructor(private readonly injectedTheme: Theme) {
    if (!ThemeManager.isValidTheme(this.injectedTheme)) {
      console.error(this.injectedTheme);
      throw new Error('Invalid theme');
    }
    this.#theme = this.injectedTheme;
  }

  public static isValidTheme(theme: Theme): boolean {
    // not checking dark as we are not using it atm
    return Object.values(theme.light).every((color) => HEX_COLOR_REGEXP.test(color));
  }

  static getInstance(theme?: Theme): ThemeManager {
    if (ThemeManager.#instance === undefined) {
      if (theme === undefined) {
        throw new Error(
          'No existing instance of ThemeManager found. You need to provide the starting theme.',
        );
      }
      ThemeManager.#instance = new ThemeManager(theme);
    }
    return ThemeManager.#instance;
  }

  public get theme(): Theme {
    return this.#theme;
  }

  public get themeVariant(): Themes[string][keyof Colors] {
    return this.#theme[this.#variant];
  }

  public color(key: keyof ColorKeys): string {
    if (this.#theme === undefined) {
      throw new Error('Cannot highlight a colour if no theme is set');
    }
    const variant = this.#theme[this.#variant];
    if (variant === undefined) {
      throw new Error('Variant not found for this theme');
    }
    return variant[key];
  }

  public highlight(keyedColor: keyof ColorKeys): string {
    if (this.#theme === undefined) {
      throw new Error('Cannot highlight a colour if no theme is set');
    }
    const variant = this.#theme[this.#variant];
    if (variant === undefined) {
      throw new Error('Variant not found for this theme');
    }
    const color = variant[keyedColor];
    if (color.length !== 7 && color.length !== 4) {
      // Should never happen since the SDK will prevent such insertions
      throw new Error('Invalid hex color');
    }
    const amount = 45;
    const bounds = {
      r: color.length === 7 ? [1, 3] : [1, 2],
      g: color.length === 7 ? [3, 5] : [2, 3],
      b: color.length === 7 ? [5, 7] : [3, 4],
    };
    const R = parseInt(color.substring(bounds.r[0], bounds.r[1]), 16);
    const G = parseInt(color.substring(bounds.g[0], bounds.g[1]), 16);
    const B = parseInt(color.substring(bounds.b[0], bounds.b[1]), 16);

    const raise = (c: number) => Math.min(255, Math.max(0, c + amount)).toString(16);
    return `#${raise(R)}${raise(G)}${raise(B)}`;
  }

  public switchTheme(theme: Theme): void {
    if (!ThemeManager.isValidTheme(theme)) {
      console.error(theme);
      throw new Error('Invalid theme');
    }
    this.#theme = theme;
    // not tested because impossible right now, but for safety
    if (!(this.#variant in this.#theme)) {
      this.#variant = 'light';
    }
    this.#onThemeChangeCb(this.#theme);
  }

  public onThemeChange(callback: OnThemeChangeCallback): void {
    this.#onThemeChangeCb = callback;
  }
}

export default ThemeManager;
