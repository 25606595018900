import { useBusHandler } from './bus-handler';

type ConfirmModel = {
  confirm: (
    options: unknown,
    loadingCallback?: (...args: Array<unknown>) => Promise<void>,
  ) => Promise<unknown>;
};

export function useConfirm(): ConfirmModel {
  const { emit, on, off } = useBusHandler();

  const confirm = (
    options: unknown,
    loadingCallback?: (...args: Array<unknown>) => Promise<void>,
  ): Promise<unknown> => {
    const finalOptions = {
      ...(options as object),
      hideOnConfirmation: loadingCallback === undefined,
    };
    emit('showConfirmation', finalOptions);

    return new Promise((resolve) => {
      const id = on('confirmationResult', (v) => {
        off(id);
        if (loadingCallback !== undefined) {
          loadingCallback(v)
            .then(() => {
              emit('hideConfirmation');
              resolve(v);
            })
            .catch((e) => {
              emit('error', e);
            });
        } else {
          resolve(v);
        }
      });
    });
  };

  return {
    confirm,
  };
}
