import { models } from '@withthegrid/amp-sdk';
import { ref, type Ref } from 'vue';
import {
  type Theme,
  type ColorKeys,
  ThemeManager,
  THEMES,
  type Colors,
} from '@web-ui-root/helpers/theme';
import { useSDK } from './sdk';
import { useBusHandler } from './bus-handler';

const DEFAULT_THEME = THEMES['Withthegrid Original'];
const themeManager = ThemeManager.getInstance(DEFAULT_THEME);

type Fn<T> = (...args: any[]) => T;
type L10nVal<K> = K extends string ? string : Fn<string>;
type L10nKey = string;
type L10n = Record<L10nKey, L10nVal<unknown>>;

const t: Record<string, L10n> = {
  en: {
    switchedTo: (name: string) => `Connectivity environment "${name}"`,
  },
  nl: {
    switchedTo: (name: string) => `Connectiviteitsomgeving "${name}"`,
  },
};

type IotEnvironmentState = {
  hashId: Ref<string | null>;
  name: Ref<string | null>;
  rights: Ref<Array<string>>;
  enforce2FA: Ref<boolean>;
  logo: Ref<string | null>;
  theme: Ref<Theme>;
};

export type IotEnvironmentModel = {
  hashId: IotEnvironmentState['hashId'];
  name: IotEnvironmentState['name'];
  enforceTwoFactorAuthentication: IotEnvironmentState['enforce2FA'];
  logo: IotEnvironmentState['logo'];
  theme: IotEnvironmentState['theme'];

  /**
   * @deprecated Use the globally defined `hasRight` function instead when possible.
   */
  hasRight: (right: string) => boolean;
  getThemeColor: (color: keyof ColorKeys) => string;
  getThemeHighlightColor: (color: keyof ColorKeys) => string;

  load: (environmentHashId: string, userLocale: string) => Promise<void>;
  set: (data: { environment: models.supplier.Supplier; environmentRights: Array<string> }) => void;
  clear: () => void;
};

const state: IotEnvironmentState = {
  hashId: ref(null),
  name: ref(null),
  rights: ref([]),
  enforce2FA: ref(false),
  logo: ref(null),
  theme: ref(themeManager.theme),
};

export function useIotEnvironment(): IotEnvironmentModel {
  const { sdk } = useSDK();
  const { emit } = useBusHandler();
  const hasRight = (right: string) => {
    if (state.rights.value.includes('ADMIN')) {
      return true;
    }

    return state.rights.value.includes(right);
  };

  const getThemeColor = (color: keyof ColorKeys) => themeManager.color(color);
  const getThemeHighlightColor = (color: keyof ColorKeys) => themeManager.highlight(color);

  const clear = () => {
    state.hashId.value = null;
    state.name.value = null;
    state.rights.value = [];
    state.enforce2FA.value = false;
  };

  const set = ({
    environment,
    environmentRights,
  }: {
    environment: models.supplier.Supplier;
    environmentRights: Array<string>;
  }): void => {
    state.hashId.value = environment.hashId;
    state.name.value = environment.name;
    state.enforce2FA.value = environment.enforceTwoFactorAuthentication;
    state.rights.value = environmentRights ?? [];
    state.logo.value = environment.logo?.url ?? null;

    const theme =
      environment.theme !== undefined && environment.theme !== null
        ? ({
            light: {
              // reordering keys
              primary: environment.theme.light.primary,
              secondary: environment.theme.light.secondary,
              accent: environment.theme.light.accent,
              neutral: environment.theme.light.neutral,
              info: environment.theme.light.info,
              warning: environment.theme.light.warning,
              error: environment.theme.light.error,
              success: environment.theme.light.success,
            },
          } satisfies Colors)
        : DEFAULT_THEME;

    themeManager.switchTheme(theme);
    state.theme.value = theme;
  };

  const load = async (hashId: string, userLocale: string): Promise<void> => {
    clear();
    state.hashId.value = hashId;

    const { response } = sdk.routes.supplier.get({ params: { hashId } });
    const { supplier, supplierRights } = await response.catch((err) => {
      emit('commsError', err);
      throw err;
    });

    set({ environment: supplier, environmentRights: supplierRights });
    emit('info', t[userLocale].switchedTo(supplier.name), undefined, 1000);
  };

  return {
    // state properties
    name: state.name,
    hashId: state.hashId,
    enforceTwoFactorAuthentication: state.enforce2FA,
    logo: state.logo,
    theme: state.theme,

    // getters
    hasRight,
    getThemeColor,
    getThemeHighlightColor,

    // methods
    clear,
    set,
    load,
  };
}
