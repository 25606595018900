import { watch } from 'vue';
import { useView } from '@web-ui-root/composables/view';
import { useUser } from '@web-ui-root/composables/user';
import { useEnvironment } from '@web-ui-root/composables/environment';
import { useLeftPanel } from '@web-ui-root/composables/left-panel';
import { useIotEnvironment } from '@web-ui-root/composables/iot-environment';
import type { LocationQueryRaw, RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import type { WatchStopHandle } from 'vue';
import router from '.';
import pushRoute from './push';
import hasAccess from './has-access';
import { setLocalStorageItem } from '../helpers/local-storage/local-storage';

const log = false;

const { defaultRoute } = useView();
const { hashId: monitoringHashId } = useEnvironment();
const { hashId: connectivityHashId } = useIotEnvironment();
const { typeKey, hashId: entityHashId, load, clear } = useLeftPanel();
const { isLoggedIn, isLoggingIn, loadEnvironment } = useUser();

function getDefaultRouteWithRedirect(insteadOfRoute?: RouteLocationNormalized): RouteLocationRaw {
  const path = defaultRoute.value;
  const query: LocationQueryRaw = {};
  if (!isLoggedIn.value && insteadOfRoute !== undefined) {
    query.redirect = insteadOfRoute.fullPath;
  }
  return { path, query };
}

async function updateState(to: RouteLocationNormalized, from?: RouteLocationNormalized) {
  if (log) {
    console.log(
      `Updating global state to router path ${to.path} with query ${JSON.stringify(to.query)}`,
    );
  }
  if (to.matched.some((m) => m.meta.environment === 'asset')) {
    if (to.params.environmentHashId !== monitoringHashId.value) {
      try {
        if (log) {
          console.log(
            `dispatching user/loadEnvironment { type: 'environment', hashId: '${to.params.environmentHashId}' }`,
          );
        }

        if (typeof to.params.environmentHashId !== 'string') {
          console.error('environmentHashId is not a string', to.params.environmentHashId);
          return;
        }

        const promises: Array<Promise<any>> = [
          loadEnvironment({ type: 'environment', hashId: to.params.environmentHashId }),
        ];

        // if we come from analytics, the eager load of the grid requires at least one element
        // or it'll error. So we route and update the environment at the same time
        if (from !== undefined && from.fullPath.includes('/anaytics')) {
          promises.push(pushRoute(to));
        }
        await Promise.all(promises);
      } catch (e) {
        if (log) {
          console.log(e);
        }
        // failed to get environment. error raised in store already
        // go to safe router path
        await pushRoute(getDefaultRouteWithRedirect(to));
        return;
      }
    }
    /* do not clear environment based on router path, eg. /new-environment does
    not have env in path, but it should not be cleared */

    // left panel
    if (
      to.params.leftPanelType !== undefined &&
      (typeKey.value !== to.params.leftPanelType || entityHashId.value !== to.params.leftPanelId)
    ) {
      if (typeof to.params.leftPanelId !== 'string') {
        console.error('leftPanelId is not a string', to.params.leftPanelId);
        return;
      }
      if (
        to.params.leftPanelType !== 'pinGroup' &&
        to.params.leftPanelType !== 'edge' &&
        to.params.leftPanelType !== 'pinGroupGrid' &&
        to.params.leftPanelType !== 'pinGrid'
      ) {
        console.error('leftPanelType is not a valid value', to.params.leftPanelType);
        return;
      }

      const leftPanelType: 'pinGroup' | 'edge' | 'pinGroupGrid' | 'pinGrid' =
        to.params.leftPanelType;
      await load({
        typeKey: leftPanelType,
        hashId: to.params.leftPanelId,
        zoomType: 2,
      });
    } else if (to.params.leftPanelType === undefined && typeKey.value !== null) {
      clear();
    }

    return;
  }

  if (to.matched.some((m) => m.meta.environment === 'deviceSupplier')) {
    if (to.params.environmentHashId !== connectivityHashId.value) {
      if (log) {
        console.log(
          `dispatching user/loadEnvironment { type: 'supplier', hashId: '${to.params.environmentHashId}'`,
        );
      }
      try {
        if (typeof to.params.environmentHashId !== 'string') {
          console.error('environmentHashId is not a string', to.params.environmentHashId);
          return;
        }
        await loadEnvironment({ type: 'supplier', hashId: to.params.environmentHashId });
      } catch (e) {
        if (log) {
          console.log(e);
        }
        // failed to get environment. error raised in store already
        // go to safe router path
        await pushRoute(getDefaultRouteWithRedirect(to));
      }
    }
  }
}

async function attachRouterToState(): Promise<void> {
  router.afterEach(() => {
    setLocalStorageItem('last-activity', Date.now());
  });

  router.beforeEach(async (to, from) => {
    if (to.name === null) {
      if (from.name === null) {
        if (log) {
          console.log('landed at an unknown page: move to root');
        }
        return getDefaultRouteWithRedirect();
      }
      if (log) {
        console.log(`Tried to move to unknown route path ${to.path}. Abort the curent navigation`);
      }
      return false;
    }

    if (isLoggingIn.value) {
      // proceed only when logged in or logged out

      let unwatch: WatchStopHandle;
      const loggingInPromise = new Promise<void>((resolve) => {
        unwatch = watch(isLoggingIn, (val) => {
          if (!val) {
            resolve();
            unwatch();
          }
        });
      });
      await loggingInPromise;

      if (!isLoggingIn.value) {
        return '/no-connection';
      }
    }

    await updateState(to, from);

    if (!hasAccess(to)) {
      if (log) {
        console.log(`user has no access to ${to.path}, redirecting to default route`);
      }
      return getDefaultRouteWithRedirect(to);
    }

    return true;
  });

  if (router.currentRoute.value.name !== null) {
    await updateState(router.currentRoute.value);
    if (hasAccess(router.currentRoute.value)) {
      return;
    }
  }
  if (log) {
    console.log(`user has no access to ${router.currentRoute.value}, redirecting to default route`);
  }
  await pushRoute(getDefaultRouteWithRedirect(router.currentRoute.value));
}

export default attachRouterToState;
