<template>
  <v-dialog
    persistent
    max-width="500"
    :model-value="true"
  >
    <v-card
      variant="outlined"
      color="primary"
      dark
    >
      <v-alert
        type="error"
        class="my-0"
      >
        <span v-t="'whoops'" />
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useUUID } from '@web-ui-root/composables/uuid';
import { onMounted } from 'vue';
import { useBusHandler } from '@web-ui-root/composables/bus-handler';

useI18n({
  messages: {
    en: {
      whoops:
        'Whoops, this part of the app cannot be found! Either you are disconnected, the server is down or you should refresh to get a new version of the user interface.',
    },
    nl: {
      whoops:
        'Whoops, dit deel van de app kan niet gevonden worden! Het kan zijn dat je geen verbinding hebt, dat de server niet bereikbaar is, of dat je de pagina moet verversen om een nieuwe versie van de user interface op te halen.',
    },
  },
});

const { uuid } = useUUID();
uuid.toString();

const { emit: busEmit } = useBusHandler();
onMounted(() => {
  busEmit('checkVersion');
});
</script>

<script lang="ts">
export default {
  name: 'ErrorDialog',
};
</script>
