/*
  There are currently listeners for the following emits:
  - emit('error', message, closePressedCallback = undefined, timeout = 6000): shows error snackbar
  - emit('info', message, closePressedCallback = undefined, timeout = 6000): shows info snackbar
  - emit('success', message, closePressedCallback = undefined, timeout = 6000):
    shows success snackbar
  - emit('added', objectName, undoCallback): shows snackbar that objectName has been added
  - emit('removed', objectName, undoCallback): shows snackbar that objectName has been removed
  - emit('errorReported'): shows snackbar that an error has occured and is reported to wtg
  - emit('commsError', error): parses an error thrown by the sdk
  - emit('zoom', lngLatBounds, zoomMethod): zoom on the map (see vector-map.vue for signature)
  - emit('reloadMap'): reload the map
  - emit('reportAdded', pinGroupHashId: string, openForNextLocation: boolean):
    pin-group-list.vue is listening
  - emit('reportDeleted'): pin-group-list.vue is listening
  - emit('tutorialCompleted'): platform.vue is listening
  - emit('checkVersion'): App.vue is listening
*/

type Callback = (...args: unknown[]) => unknown;
type NoOpCallback = () => void;

type UnqualifiedArgs = Array<unknown>;

interface Listener {
  callback: Callback;
  id: number;
}

type BusHandlerModel = {
  emit: (eventName: string, ...args: UnqualifiedArgs) => UnqualifiedArgs | NoOpCallback;
  on: (eventName: string, callback: Callback) => number;
  off: (id: number) => boolean;
};

const listeners: Record<string, Array<Listener>> = {};

let counter = 0;

export const useBusHandler = (): BusHandlerModel => {
  const emit = (eventName: string, ...args: UnqualifiedArgs): UnqualifiedArgs | NoOpCallback => {
    if (listeners[eventName] === undefined) {
      /* eslint-disable no-empty-function */
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
      /* eslint-enable no-empty-function */
    }

    const callbackResults = listeners[eventName].map((listener) => listener.callback(...args));

    return callbackResults;
  };

  const on = (eventName: string, callback: Callback): number => {
    if (listeners[eventName] === undefined) {
      listeners[eventName] = [];
    }

    listeners[eventName].push({
      callback,
      id: counter + 1,
    });
    counter += 1;

    return counter;
  };

  const off = (id: number): boolean =>
    Object.values(listeners).some((eventListeners) => {
      for (let i = eventListeners.length - 1; i >= 0; i -= 1) {
        if (eventListeners[i].id === id) {
          eventListeners.splice(i, 1);
          return true;
        }
      }
      return false;
    });

  return {
    emit,
    on,
    off,
  };
};
