<template>
  <v-dialog
    :fullscreen="internalFullscreen"
    :max-width="internalMaxWidth"
    :scrollable="scrollable"
    :model-value="internalValue"
    :persistent="persistent"
    :content-class="`w-dialog ${formattedContentClasses}`"
    @update:model-value="onInput"
  >
    <v-card
      :loading="state === 'loading'"
      class="dialog-card"
      style="overflow: visible"
    >
      <div
        v-if="title !== null || $slots.title !== undefined"
        class="bg-grey-lighten-2 flex-no-grow mb-0 px-2 py-2 w-dialog-title"
      >
        <div style="flex: 1 1 auto; overflow: hidden">
          <slot name="title">
            <v-card-title class="overflowed-selection">
              <text-overflow>
                {{ title }}
              </text-overflow>
            </v-card-title>
            <v-card-subtitle
              v-if="subTitle !== null"
              class="overflowed-selection subtitle"
            >
              {{ subTitle }}
            </v-card-subtitle>
          </slot>
        </div>
      </div>

      <slot name="header" />

      <template v-if="showToolbar">
        <v-toolbar
          color="white"
          flat
          density="compact"
          class="flex-no-grow"
        >
          <slot name="toolbar" />
        </v-toolbar>
        <v-divider />
      </template>

      <v-card-text
        :class="cardTextClasses"
        :style="cardTextStyle"
      >
        <slot name="default" />
      </v-card-text>

      <template v-if="!hideActions">
        <v-spacer v-if="!noSpacer" />
        <v-divider />
        <v-card-actions class="flex-no-grow card-actions-fab px-xs-6 px-sm-6 px-md-6 px-lg-4">
          <!-- [left-actions]      [right-actions] [Close/Discard?] [New?] [main-action] -->
          <slot name="left-actions" />
          <v-spacer />
          <slot name="right-actions" />
          <v-btn
            v-if="closable && !hideCloseButton"
            variant="outlined"
            :disabled="state === 'saving'"
            @click="() => onInput(false)"
          >
            {{ t(state === 'tainted' ? 'discard' : 'close') }}
          </v-btn>
          <v-btn
            v-if="addable"
            color="primary"
            variant="elevated"
            :disabled="state === 'saving'"
            @click="emit('new')"
          >
            {{ t('new') }}
          </v-btn>
          <slot name="main-action" />
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import TextOverflow from '@web-ui-root/components/text-overflow.vue';
import { useHasUnsavedChanges } from '@web-ui-root/composables/has-unsaved-changes';
import { useView } from '@web-ui-root/composables/view';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { useUUID } from '@web-ui-root/composables/uuid';
import { useConfirm } from '@web-ui-root/composables/confirm';

type Props = {
  contentClasses?: string | string[];
  addable?: boolean;
  cardTextClasses?: string | string[];
  cardTextStyle?: string;
  fullscreen?: boolean;
  hideActions?: boolean;
  noSpacer?: boolean;
  maxWidth?: string | number;
  scrollable?: boolean;
  persistent?: boolean;
  closable?: boolean;
  hideCloseButton?: boolean;
  showToolbar?: boolean;
  subTitle?: string | null;
  title?: string | null;
  modelValue?: boolean;
  state?: 'pristine' | 'tainted' | 'saving' | 'loading';
};

const props = withDefaults(defineProps<Props>(), {
  contentClasses: 'w-dialog',
  addable: false,
  cardTextClasses: 'pt-5',
  cardTextStyle: undefined,
  fullscreen: undefined,
  hideActions: false,
  noSpacer: false,
  maxWidth: '500px',
  scrollable: true,
  persistent: false,
  closable: true,
  hideCloseButton: false,
  showToolbar: false,
  subTitle: null,
  title: null,
  modelValue: true,
  state: 'pristine',
});

const messages = {
  en: {
    confirmationText:
      'You have unsaved changes. Do you want to close this dialog without saving them?',
    confirmButtonText: 'Close without saving',
    cancelButtonText: 'Do not close',
    close: 'Close',
    discard: 'Discard',
    new: 'New',
  },
  nl: {
    confirmationText:
      'Je wijzigingen zijn nog niet opgeslagen. Wil je dit venster sluiten zonder ze op te slaan?',
    confirmButtonText: 'Sluiten zonder opslaan',
    cancelButtonText: 'Niet sluiten',
    close: 'Sluiten',
    discard: 'Sluiten',
    new: 'Nieuw',
  },
};

const { uuid } = useUUID();
const { t } = useI18n({ messages });
const { loadComponent: viewLoadComponent, unloadComponent: viewUnloadComponent } = useView();
const { mdAndUp } = useDisplay();
const { hasUnsavedChanges, awaitingRouteChangeConfirmation, beforeRouteLeaveGuard } =
  useHasUnsavedChanges();
const { confirm } = useConfirm();

const internalValue = ref(true);

const emit = defineEmits<{
  new: [];
  'update:modelValue': [value: boolean];
}>();

defineExpose({
  hasUnsavedChanges,
  // exposing methods does nothing, but at least it's easier for us to read
  // https://github.com/vuejs/core/issues/5540#issuecomment-1541473789
  onInput,
  beforeRouteLeaveGuard,
});

const formattedContentClasses = computed(() => {
  if (Array.isArray(props.contentClasses)) {
    return props.contentClasses.join(' ');
  }
  return props.contentClasses;
});
const internalFullscreen = computed(() => {
  if (props.fullscreen !== undefined) {
    return props.fullscreen;
  }
  return !mdAndUp.value;
});
const internalMaxWidth = computed(() => {
  if (props.fullscreen === true) {
    return undefined;
  }
  return mdAndUp.value ? props.maxWidth : undefined;
});

watch(
  () => props.modelValue,
  (val) => {
    internalValue.value = val;
  },
);

watch(internalValue, (val) => {
  if (val) {
    loadComponent();
  } else {
    unloadComponent();
  }
  updateHasUnsavedChanges();
});

watch(
  () => props.title,
  () => {
    loadComponent();
  },
);

watch(
  () => props.state,
  () => {
    updateHasUnsavedChanges();
  },
);

onBeforeMount(() => {
  internalValue.value = props.modelValue;
  updateHasUnsavedChanges();
});

onMounted(() => {
  loadComponent();
});

onBeforeUnmount(() => {
  unloadComponent();
});

function loadComponent() {
  if (internalValue.value && props.title !== null) {
    viewLoadComponent({ uuid: uuid.value, title: props.title });
  }
}

function unloadComponent() {
  viewUnloadComponent(uuid.value);
}

function updateHasUnsavedChanges() {
  hasUnsavedChanges.value = props.state === 'tainted' && internalValue.value;
}

// public
async function onInput(val: boolean) {
  if (
    props.state === 'saving' ||
    props.closable === false ||
    awaitingRouteChangeConfirmation.value
  ) {
    return;
  }
  if (val === false && props.state === 'tainted') {
    awaitingRouteChangeConfirmation.value = true;
    const proceed = await confirm({
      text: t('confirmationText'),
      confirmButtonText: t('confirmButtonText'),
      cancelButtonText: t('cancelButtonText'),
    });
    awaitingRouteChangeConfirmation.value = false;
    if (!proceed) {
      return;
    }
  }
  internalValue.value = val;

  emit('update:modelValue', val);
}
</script>

<style>
.w-dialog {
  /* allows positioning of the fab without actions */
  overflow-y: visible !important;
}
.w-dialog-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
</style>

<style scoped>
.dialog-card {
  max-height: 80vh !important;
  display: flex;
  flex-flow: column nowrap;
}

.flex-container {
  display: flex;
}

.flex-no-grow {
  flex-grow: 0 !important;
}

.dialog-card-actions-fab {
  position: relative; /* required for fab */
  min-height: 30px;
}

.overflowed-selection {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fab-without-actions-fullscreen {
  bottom: 16px !important;
}

.v-card.dialog-card > .v-card-text {
  font-size: 0.875rem;
}
.dialog-card .subtitle {
  margin-top: -8px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 1;
  padding-bottom: 10px;
}
</style>

<style>
.dialog-card .v-field--active .v-field__input {
  color: rgba(0, 0, 0, 0.87);
}
</style>
