// todo: this is now a composable so probably should be moved out of helpders

import { ref, type Ref } from 'vue';
import {
  Map as MapLibreMap,
  type LngLat,
  type MapMouseEvent,
  type MapGeoJSONFeature,
} from 'maplibre-gl';

const hoverEvents = ['mouseenter', 'mousemove', 'mouseleave', 'mouseout'];

export type MapInputEvent = {
  maplibreEvent: MapMouseEvent;
  eventLayers: string[];
  eventFeatures: MapGeoJSONFeature[];
  layerFeatures?: MapGeoJSONFeature[];
  lngLat: LngLat;
};

interface EventHandler {
  (event: MapInputEvent): boolean;
}

interface LayerHandlers {
  [eventName: string]: {
    [layerId: string]: EventHandler[];
  };
}

interface DefaultHandlers {
  [eventName: string]: EventHandler[];
}

interface EventListeners {
  [eventName: string]:
    | {
        listener: (event: MapMouseEvent) => void;
        subscribers: number;
      }
    | undefined;
}

export interface LayerEventHandler {
  on: (eventName: string, layerId: string | null, callback: EventHandler) => () => void;
}

export const useLayerEventHandler = (map: MapLibreMap): LayerEventHandler => {
  const handlers: Ref<LayerHandlers> = ref({});
  const defaultHandlers: Ref<DefaultHandlers> = ref({});
  const listeners: Ref<EventListeners> = ref({});
  const lastHoverEventLayerIds: Ref<string[]> = ref([]);

  const handleEvent = (event: MapMouseEvent): boolean => {
    const eventName = event.type;

    // This gets the impacted features in the correct layer order
    let eventFeatures: MapGeoJSONFeature[];
    try {
      eventFeatures = map.queryRenderedFeatures(event.point);
    } catch (e) {
      console.log(event);
      /**
       * Mapbox can throw the following error:
       * Uncaught Error: feature index out of bounds
       *   at Pa.feature (mapbox-gl.js:29)
       *   at Ru.loadMatchingFeature (mapbox-gl.js:29)
       *   at Ru.lookupSymbolFeatures (mapbox-gl.js:29)
       *   at h (mapbox-gl.js:33)
       *   at mapbox-gl.js:33
       *   at r.queryRenderedFeatures (mapbox-gl.js:33)
       *   at n.queryRenderedFeatures (mapbox-gl.js:33)
       *   at t.value (mapbox-layer-event-handler.js:97)
       *   at n.listeners.<computed>.listeners.<computed> (mapbox-layer-event-handler.js:186)
       *   at n.St.fire (mapbox-gl.js:29)
       *   at HTMLDivElement.<anonymous> (mapbox-gl.js:33)
       *   at HTMLDivElement.i (raven.js:376)
       */
      return true;
    }

    // Group eventFeatures into a set of layers, only saving layerIds
    const sortedLayers: string[] = eventFeatures.reduce(
      (ids: string[], feature: MapGeoJSONFeature) => {
        const nextLayerId = feature.layer.id;
        if (ids.indexOf(nextLayerId) === -1) {
          return ids.concat([nextLayerId]);
        }
        return ids;
      },
      [],
    );

    // Add the layers and features info to the event
    let doNotBubbleEvent = false;

    // Loop through each of the sorted layers starting with the first (top-most clicked layer)
    // Call the handler for each layer in order, and potentially stop propagating the event

    const currentHoverEventLayerIds: string[] = [];

    for (let i = 0; i < sortedLayers.length; i += 1) {
      const layerId = sortedLayers[i];
      let featureEventName = eventName;
      if (['mousemove', 'mouseenter'].includes(eventName)) {
        currentHoverEventLayerIds.push(layerId);
        const hasLayer = lastHoverEventLayerIds.value.includes(layerId);
        if (hasLayer) {
          featureEventName = 'mousemove';
        } else {
          featureEventName = 'mouseenter';
        }
      }

      if (
        handlers.value[featureEventName] !== undefined &&
        handlers.value[featureEventName][layerId] !== undefined
      ) {
        const layerFeatures = eventFeatures.filter((feature) => feature.layer.id === layerId);
        // Call the layer handler for this layer, giving the clicked features
        // stop when false is returned, and store that to not call the defaultHandler
        doNotBubbleEvent = handlers.value[featureEventName][layerId].some(
          (callback) =>
            !callback({
              maplibreEvent: event,
              eventLayers: sortedLayers,
              eventFeatures,
              layerFeatures,
              lngLat: event.lngLat,
            }),
        );

        if (doNotBubbleEvent) {
          break;
        }
      }
    }

    if (hoverEvents.includes(eventName)) {
      lastHoverEventLayerIds.value
        .filter((x) => !currentHoverEventLayerIds.includes(x))
        .forEach((layerId) => {
          if (
            handlers.value.mouseleave !== undefined &&
            handlers.value.mouseleave[layerId] !== undefined
          ) {
            // console.log(`mouseleave on ${layerId}`);

            // stop when false is returned
            handlers.value.mouseleave[layerId].some(
              (callback) =>
                !callback({
                  maplibreEvent: event,
                  eventLayers: sortedLayers,
                  eventFeatures,
                  lngLat: event.lngLat,
                }),
            );
          }
        });
    }

    lastHoverEventLayerIds.value = currentHoverEventLayerIds;

    if (doNotBubbleEvent) {
      return false;
    }

    if (defaultHandlers.value[eventName] === undefined) {
      return true;
    }

    defaultHandlers.value[eventName].forEach((callback) =>
      callback({
        maplibreEvent: event,
        eventLayers: sortedLayers,
        eventFeatures,
        lngLat: event.lngLat,
      }),
    );

    return true;
  };

  const addListener = (name: string) => {
    if (listeners.value[name] === undefined) {
      // Register a map event for the given event name
      listeners.value[name] = {
        listener: (event) => handleEvent(event),
        subscribers: 1,
      };
      map.on(name, listeners.value[name]!.listener);
      return;
    }
    listeners.value[name]!.subscribers += 1;
  };

  const dropListenerIfUnused = (name: string, decreaseBy = 1) => {
    if (listeners.value[name] !== undefined) {
      listeners.value[name]!.subscribers -= decreaseBy;
      if (listeners.value[name]!.subscribers <= 0) {
        map.off(name, listeners.value[name]!.listener);
        listeners.value[name] = undefined;
      }
    }
  };

  /**
   *
   * @param eventName
   * @param layerId
   * @param callback Should return a boolean that indicates if it should bubble or not
   */
  const on = (
    eventName: string, // todo: should narrow this with a type
    layerId: string | null,
    callback: EventHandler,
  ): (() => void) => {
    const isHoverEvent = layerId !== null && ['mouseenter', 'mouseleave'].includes(eventName);

    if (isHoverEvent) {
      hoverEvents.forEach((e) => addListener(e));
    } else {
      addListener(eventName);
    }

    if (layerId !== null) {
      if (handlers.value[eventName] === undefined) {
        // Create new event name keys in our storage maps
        handlers.value[eventName] = {};
      }

      if (handlers.value[eventName][layerId] === undefined) {
        handlers.value[eventName][layerId] = [];
      }

      handlers.value[eventName][layerId].push(callback);

      return () => {
        const index = handlers.value[eventName][layerId].findIndex((c) => c === callback);
        if (index === -1) {
          console.log('Cannot find callback');
          return;
        }
        handlers.value[eventName][layerId].splice(index, 1);
        if (isHoverEvent) {
          hoverEvents.forEach((e) => dropListenerIfUnused(e));
        } else {
          dropListenerIfUnused(eventName);
        }
      };
    }

    if (defaultHandlers.value[eventName] === undefined) {
      defaultHandlers.value[eventName] = [];
    }
    defaultHandlers.value[eventName].push(callback);
    return () => {
      const index = defaultHandlers.value[eventName].findIndex((c) => c === callback);
      if (index === -1) {
        console.log('Cannot find callback');
        return;
      }
      defaultHandlers.value[eventName].splice(index, 1);
      dropListenerIfUnused(eventName);
    };
  };

  return {
    on,
  };
};
