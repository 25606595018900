import { ref, type Ref, onBeforeUnmount, watch } from 'vue';
import { type NavigationGuardNext } from 'vue-router';

import { useConfirm } from '@web-ui-root/composables/confirm';
import { useUser } from './user';

type HasUnsavedChangesModel = {
  hasUnsavedChanges: Ref<boolean>;
  awaitingRouteChangeConfirmation: Ref<boolean>;

  beforeRouteLeaveGuard: (next: NavigationGuardNext, textOverride?: string) => Promise<void>;
};

export function useHasUnsavedChanges(): HasUnsavedChangesModel {
  const { locale } = useUser();
  const messages = {
    en: {
      beforeLeavePrompt: 'You have unsaved changes. Do you want to continue without saving them?',
    },
    nl: {
      beforeLeavePrompt:
        'Je wijzigingen zijn nog niet opgeslagen. Wil je doorgaan zonder ze op te slaan?',
    },
  };

  const { confirm } = useConfirm();

  const hasUnsavedChanges = ref(false);
  const awaitingRouteChangeConfirmation = ref(false);

  function beforeWindowUnload(evt: BeforeUnloadEvent): void {
    // Possible extra debug information for https://github.com/withthegrid/platform/issues/4404
    console.log('Unsaved changes dialog/alert triggered');
    evt.returnValue = true;
  }

  onBeforeUnmount(() => {
    window.removeEventListener('beforeunload', beforeWindowUnload);
  });

  watch(hasUnsavedChanges, (tainted: boolean) => {
    const addOrRemoveListener = tainted ? window.addEventListener : window.removeEventListener;

    addOrRemoveListener('beforeunload', beforeWindowUnload);
  });

  async function beforeRouteLeaveGuard(
    next: NavigationGuardNext,
    textOverride?: string,
  ): Promise<void> {
    if (!hasUnsavedChanges.value) {
      next();
      return;
    }

    if (awaitingRouteChangeConfirmation.value) {
      next(false);
      return;
    }

    const isTextOverrideValid = textOverride !== undefined && textOverride.length > 0;
    const text = isTextOverrideValid ? textOverride : messages[locale.value].beforeLeavePrompt;

    awaitingRouteChangeConfirmation.value = true;
    const answer = await confirm({ text });
    awaitingRouteChangeConfirmation.value = false;

    if (answer === true) {
      next();
    } else {
      next(false);
    }
  }

  return {
    hasUnsavedChanges,
    awaitingRouteChangeConfirmation,
    beforeRouteLeaveGuard,
  };
}
