import { DateTime, type DateObjectUnits } from 'luxon';

const dateUnits = ['year', 'quarter', 'month', 'week', 'day'] as const;
const timeUnits = ['hour', 'minute', 'second', 'millisecond'] as const;
type TimeUnits = (typeof timeUnits)[number];
type DateUnits = (typeof dateUnits)[number];
type DateTimeUnit = TimeUnits | DateUnits;
type DurationObjectUnits = Partial<Record<DateTimeUnit, number>>;
type DurationObjectUnitsPlural = Partial<Record<`${DateTimeUnit}s`, number>>;
type DurationLikeObject = DurationObjectUnits & DurationObjectUnitsPlural;
type DurationUnit = keyof DurationLikeObject;

const YYYY_MM_DD_HH_MM_SS = 'yyyy-MM-dd HH:mm:ss';
const YYYY_MM_DD_HH_MM = 'yyyy-MM-dd HH:mm';
const YYYY_MM_DD_T_HH_MM = "yyyy-MM-dd'T'HH:mm";
const YYYY_MM_DD = 'yyyy-MM-dd';
const YYYY_MM = 'yyyy-MM';
const YYYY = 'yyyy';
const D_MMM_HH_MM = 'd MMM HH:mm';
const D_MMM_YY_HH_MM = 'd MMM yy HH:mm';
const D_MMM_YYYY = 'd MMM yyyy';
const D_MMM_YY = 'd MMM yy';
const D_MMM = 'd MMM';
const DD_MM_YY_HH_MM_SS_SSS = 'dd-MM-yy HH:mm:ss.SSS';
const DD_MM_YY_HH_MM_SS = 'dd-MM-yy HH:mm:ss';
const DD_MM_YY_HH_MM = 'dd-MM-yy HH:mm';
const DD_MM_YY = 'dd-MM-yy';
const DD_MM_YYYY = 'dd-MM-yyyy';
const MMM_YYYY = 'MMM yyyy';
const HH_MM_SS_SSS = 'HH:mm:ss.SSS';
const HH_MM_SS = 'HH:mm:ss';
const HH_MM = 'HH:mm';
const HH = 'HH';

class DateTimeWrapper {
  private luxonDateTime: DateTime;

  constructor(dateTime: DateTime) {
    if (dateTime === undefined) {
      throw new Error('Unable to initialise an undefined date');
    }
    this.luxonDateTime = dateTime;
  }

  get isValid(): boolean {
    return this.luxonDateTime.isValid;
  }

  get millisecond(): number {
    return this.luxonDateTime.millisecond;
  }

  get second(): number {
    return this.luxonDateTime.second;
  }

  get minute(): number {
    return this.luxonDateTime.minute;
  }

  get hour(): number {
    return this.luxonDateTime.hour;
  }

  toFormat(format: string): string {
    return this.luxonDateTime.toFormat(format);
  }

  toJSDate(): Date {
    return this.luxonDateTime.toJSDate();
  }

  startOf(dateTimeUnit: DateTimeUnit): DateTimeWrapper {
    return new DateTimeWrapper(this.luxonDateTime.startOf(dateTimeUnit));
  }

  endOf(dateTimeUnit: DateTimeUnit): DateTimeWrapper {
    return new DateTimeWrapper(this.luxonDateTime.endOf(dateTimeUnit));
  }

  setLocale(locale: string): DateTimeWrapper {
    return new DateTimeWrapper(this.luxonDateTime.setLocale(locale));
  }

  hasSame(dateTimeWrapper: DateTimeWrapper, unit: DateTimeUnit): boolean {
    return this.luxonDateTime.hasSame(dateTimeWrapper.luxonDateTime, unit);
  }

  minus(duration: DurationLikeObject): DateTimeWrapper {
    return new DateTimeWrapper(this.luxonDateTime.minus(duration));
  }

  plus(duration: DurationLikeObject): DateTimeWrapper {
    return new DateTimeWrapper(this.luxonDateTime.plus(duration));
  }

  toISO(): string | null {
    return this.luxonDateTime.toISO();
  }

  diff(dateTimeWrapper: DateTimeWrapper, unit: DurationUnit): DurationLikeObject {
    return this.luxonDateTime.diff(dateTimeWrapper.luxonDateTime, unit).toObject();
  }

  valueOf(): number {
    return this.luxonDateTime.valueOf();
  }

  set(value: DateObjectUnits): DateTimeWrapper {
    return new DateTimeWrapper(this.luxonDateTime.set(value));
  }

  get(key: keyof DateTime): number {
    return this.luxonDateTime.get(key);
  }

  static now(): DateTimeWrapper {
    return new DateTimeWrapper(DateTime.now());
  }

  static fromFormat(value: string, format: string): DateTimeWrapper {
    return new DateTimeWrapper(DateTime.fromFormat(value, format));
  }

  static fromJSDate(date: Date): DateTimeWrapper {
    return new DateTimeWrapper(DateTime.fromJSDate(date));
  }

  static fromISO(value: string): DateTimeWrapper {
    return new DateTimeWrapper(DateTime.fromISO(value));
  }

  static min(dateTimeWrappers: DateTimeWrapper[]): DateTimeWrapper {
    if (dateTimeWrappers.length === 0) {
      throw new Error('Unable to get minimum date from an empty array.');
    }
    const dateTimes = dateTimeWrappers.map((it) => it.luxonDateTime);
    return new DateTimeWrapper(DateTime.min(...dateTimes));
  }

  static max(dateTimeWrappers: DateTimeWrapper[]): DateTimeWrapper {
    if (dateTimeWrappers.length === 0) {
      throw new Error('Unable to get maximum date from an empty array.');
    }
    const dateTimes = dateTimeWrappers.map((it) => it.luxonDateTime);
    return new DateTimeWrapper(DateTime.max(...dateTimes));
  }
}

export default DateTimeWrapper;
export {
  YYYY_MM_DD_HH_MM_SS,
  YYYY_MM_DD_HH_MM,
  YYYY_MM_DD_T_HH_MM,
  YYYY_MM_DD,
  YYYY_MM,
  YYYY,
  D_MMM_HH_MM,
  D_MMM_YY_HH_MM,
  D_MMM_YYYY,
  D_MMM_YY,
  D_MMM,
  DD_MM_YY_HH_MM_SS_SSS,
  DD_MM_YY_HH_MM_SS,
  DD_MM_YY_HH_MM,
  DD_MM_YY,
  DD_MM_YYYY,
  MMM_YYYY,
  HH_MM_SS_SSS,
  HH_MM_SS,
  HH_MM,
  HH,
  type DateTimeUnit,
  type DurationLikeObject,
  type DurationUnit,
};
