<template>
  <v-progress-linear
    indeterminate
    color="primary"
    class="ma-0"
    height="4"
  />
</template>

<script setup lang="ts">
import { useUUID } from '@web-ui-root/composables/uuid';

const { uuid } = useUUID();
uuid.value.toString();
</script>
