import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from './local-storage';

const userLocalStorageKey = 'userData';

function getLastEnvironmentHashId(): string | null {
  const userStorage = getLocalStorageItem(userLocalStorageKey, true);
  if (userStorage === null) {
    return null;
  }
  if (userStorage.lastEnvironmentHashId === undefined) {
    return null;
  }

  return userStorage.lastEnvironmentHashId;
}

function setLastEnvironmentHashId(lastEnvironmentHashId: string | null): void {
  let userStorage = getLocalStorageItem(userLocalStorageKey, true);
  if (userStorage === null) {
    userStorage = {};
  }
  userStorage.lastEnvironmentHashId = lastEnvironmentHashId;

  setLocalStorageItem(userLocalStorageKey, userStorage, true);
}

function clearUser(): void {
  removeLocalStorageItem(userLocalStorageKey);
}

export { getLastEnvironmentHashId, setLastEnvironmentHashId, clearUser };
