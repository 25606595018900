import { type Themes } from '@web-ui-root/helpers/theme';

export const HEX_COLOR_REGEXP = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

export const THEMES: Themes = {
  'Withthegrid Original': {
    light: {
      primary: '#f6a500',
      secondary: '#464600',
      accent: '#ffab40',
      neutral: '#9c9c9c',
      info: '#64b5f6',
      warning: '#ffa000',
      error: '#e57373',
      success: '#81c784',
    },
  },
  Withthegrid: {
    light: {
      primary: '#4271FF',
      secondary: '#1B2953',
      accent: '#374151',
      neutral: '#9c9c9c',
      info: '#3FA5F8',
      warning: '#FFA000',
      error: '#E57373',
      success: '#81C784',
    },
  },
  'Material blue': {
    light: {
      primary: '#03a9f4',
      secondary: '#64b5f6',
      accent: '#ff80ab',
      neutral: '#9c9c9c',
      info: '#3FA5F8',
      warning: '#FFA000',
      error: '#E57373',
      success: '#81C784',
    },
  },
  'Material purple': {
    light: {
      primary: '#9c27b0',
      secondary: '#ba68c8',
      accent: '#ffca28',
      neutral: '#9c9c9c',
      info: '#3FA5F8',
      warning: '#FFA000',
      error: '#E57373',
      success: '#81C784',
    },
  },
  'Material red': {
    light: {
      primary: '#e91e63',
      secondary: '#f06292',
      accent: '#42a5f5',
      neutral: '#9c9c9c',
      info: '#3FA5F8',
      warning: '#FFA000',
      error: '#E57373',
      success: '#81C784',
    },
  },
  'Material orange': {
    light: {
      primary: '#ff5722',
      secondary: '#e64a19',
      accent: '#3f51b5',
      neutral: '#9c9c9c',
      info: '#3FA5F8',
      warning: '#FFA000',
      error: '#E57373',
      success: '#81C784',
    },
  },
  'Material green': {
    light: {
      primary: '#009688',
      secondary: '#00897b',
      accent: '#4dd0e1',
      neutral: '#9c9c9c',
      info: '#3FA5F8',
      warning: '#FFA000',
      error: '#E57373',
      success: '#81C784',
    },
  },
  'Material grey': {
    light: {
      primary: '#9e9e9e',
      secondary: '#757575',
      accent: '#42a5f5',
      neutral: '#9c9c9c',
      info: '#3FA5F8',
      warning: '#FFA000',
      error: '#E57373',
      success: '#81C784',
    },
  },
} as const;
