import { createRouter, createWebHashHistory, type RouteRecordRaw } from 'vue-router';

import { lazyRouterViewLoader } from '../helpers/lazy-load-view';

import monitoringEnvironmentRoutes from './monitoring-environment';
import connectivityEnvironmentRoute from './connectivity-environment';
import { useUser } from '../composables/user';

const Login = lazyRouterViewLoader(() => import('@web-ui-root/views/user/login-view.vue'));
const NoConnection = lazyRouterViewLoader(() => import('@web-ui-root/views/no-connection.vue'));
const PasswordReset = lazyRouterViewLoader(
  () => import('@web-ui-root/views/user/password-reset.vue'),
);
const ValidateEmail = lazyRouterViewLoader(
  () => import('@web-ui-root/views/user/validate-email.vue'),
);
const SetUp = lazyRouterViewLoader(() => import('@web-ui-root/views/user/set-up.vue'));
const SsoRedirect = lazyRouterViewLoader(() => import('@web-ui-root/views/user/sso-redirect.vue'));
const Environments = lazyRouterViewLoader(
  () => import('@web-ui-root/views/environments/environments.vue'),
  true,
);
const NewEnvironment = lazyRouterViewLoader(
  () => import('@web-ui-root/views/environments/new-environment.vue'),
  true,
);
const Expired = lazyRouterViewLoader(
  () => import('@web-ui-root/views/environments/expired.vue'),
  true,
);

const ChangePassword = lazyRouterViewLoader(
  () => import('@web-ui-root/views/user/change-password.vue'),
  true,
);
const TwoFactorAuthenticationRequired = lazyRouterViewLoader(
  () => import('@web-ui-root/views/user/two-factor-authentication/auth-required.vue'),
  true,
);
const EnableTwoFactorAuthentication = lazyRouterViewLoader(
  () => import('@web-ui-root/views/user/two-factor-authentication/auth-enable.vue'),
  true,
);
const DisableTwoFactorAuthentication = lazyRouterViewLoader(
  () => import('@web-ui-root/views/user/two-factor-authentication/auth-disable.vue'),
  true,
);
const SecuritySettings = lazyRouterViewLoader(
  () => import('@web-ui-root/views/user/security-settings.vue'),
  true,
);
const Sessions = lazyRouterViewLoader(
  () => import('@web-ui-root/views/user/user-sessions.vue'),
  true,
);

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: SsoRedirect,
    name: 'sso-redirect',
    meta: { requiredRight: 'LOGGED_OUT' },
  },
  {
    path: '/set-up',
    component: SetUp,
    meta: { requiredRight: 'LOGGED_IN' },
  },
  {
    path: '/environments',
    component: Environments,
    meta: { requiredRight: 'LOGGED_IN' },
  },
  {
    path: '/new-environment/:environmentType(monitoring|connectivity)',
    sensitive: true,
    component: NewEnvironment,
    props: (route) => ({ environmentType: route.params.environmentType }),
    beforeEnter: (_to, _from, next): void => {
      const { hasRight } = useUser();

      if (hasRight('ADMIN')) {
        next();
      } else {
        next({ path: '/set-up' });
      }
    },
    meta: { requiredRight: 'LOGGED_IN' },
  },
  {
    path: '/security-settings',
    component: SecuritySettings,
    meta: { requiredRight: 'LOGGED_IN' },
  },
  {
    path: '/password',
    component: ChangePassword,
    meta: { requiredRight: 'LOGGED_IN' },
  },
  {
    path: '/two-factor-authentication-required',
    props: (route) => ({
      redirect: route.query.redirect,
    }),
    component: TwoFactorAuthenticationRequired,
    meta: { requiredRight: 'LOGGED_IN' },
  },
  {
    path: '/enable-two-factor-authentication',
    name: 'enable-2fa',
    props: (route) => ({ redirect: route.query.redirect }),
    component: EnableTwoFactorAuthentication,
    meta: { requiredRight: 'LOGGED_IN' },
  },
  {
    path: '/disable-two-factor-authentication',
    props: (route) => ({ redirect: route.query.redirect }),
    component: DisableTwoFactorAuthentication,
    meta: { requiredRight: 'LOGGED_IN' },
  },
  {
    path: '/expired',
    component: Expired,
    meta: { requiredRight: 'LOGGED_IN' },
  },
  {
    path: '/login',
    props: (route) => ({ redirect: route.query.redirect }),
    component: Login,
    meta: { requiredRight: 'LOGGED_OUT' },
  },
  {
    path: '/no-connection',
    component: NoConnection,
    meta: { requiredRight: 'LOGGED_OUT' },
  },
  {
    path: '/set-password/:userId/:userToken',
    component: PasswordReset,
    props: (route) => ({
      userHashId: route.params.userId,
      userToken: route.params.userToken,
      source: 'set',
    }),
    meta: { requiredRight: 'LOGGED_OUT' },
  },
  {
    path: '/forgot-password/:userId/:userToken',
    component: PasswordReset,
    props: (route) => ({
      userHashId: route.params.userId,
      userToken: route.params.userToken,
      source: 'forgot',
    }),
    meta: { requiredRight: 'LOGGED_OUT' },
  },
  {
    path: '/validate-email/:userId/:userToken',
    component: ValidateEmail,
    props: (route) => ({ userHashId: route.params.userId, userToken: route.params.userToken }),
    meta: { requiredRight: 'LOGGED_OUT' },
  },
  ...monitoringEnvironmentRoutes,
  ...connectivityEnvironmentRoute,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/environments',
  },
  {
    path: '/sessions',
    component: Sessions,
    meta: { requiredRight: 'LOGGED_IN' },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
