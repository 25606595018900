<template>
  <!-- :closable="false" because this dialog has it's own cancel/close control -->
  <w-dialog
    :model-value="isShown"
    :fullscreen="false"
    :closable="false"
    :state="saving ? 'saving' : 'pristine'"
    @update:model-value="() => choose(false)"
  >
    <p>{{ text }}</p>

    <template #right-actions>
      <v-btn
        color="secondary"
        variant="text"
        :disabled="saving"
        @click="() => choose(false)"
      >
        {{ cancelButtonText }}
      </v-btn>
    </template>
    <template #main-action>
      <v-btn
        color="primary"
        variant="elevated"
        :loading="saving"
        @click="() => choose(true)"
      >
        {{ confirmButtonText }}
      </v-btn>
    </template>
  </w-dialog>
</template>

<script lang="ts" setup>
import { ref, type Ref, onMounted, onBeforeUnmount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useBusHandler } from '@web-ui-root/composables/bus-handler';
import Invariant from '@web-ui-root/helpers/invariant';
import { useUUID } from '@web-ui-root/composables/uuid';
import WDialog from './w-dialog.vue';

type Options = {
  text: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  hideOnConfirmation: boolean;
};

/**
 * Type narrows the confirmation dialog options
 */
function assertBusArgs(options: unknown): asserts options is Options {
  if (typeof options !== 'object' || options === null) {
    throw new Invariant('confirmation dialog options is not a valid object', options);
  }

  Invariant.assert('text' in options, 'confirmation dialog text is missing', options);
  Invariant.assert(
    'text' in options && typeof options?.text === 'string',
    'confirmation dialog text is not a string',
    options,
  );

  if ('confirmButtonText' in options) {
    Invariant.assert(
      typeof options.confirmButtonText === 'string',
      'confirmation dialog confirm button text is not a string',
      options.confirmButtonText,
    );
  }

  if ('cancelButtonText' in options) {
    Invariant.assert(
      typeof options.cancelButtonText === 'string',
      'confirmation dialog cancel button text is not a string',
      options.cancelButtonText,
    );
  }

  if ('hideOnConfirmation' in options) {
    Invariant.assert(
      typeof options.hideOnConfirmation === 'boolean',
      'confirmation dialog hideOnConfirmation is not a boolean',
      options.hideOnConfirmation,
    );
  }
}

const { uuid } = useUUID();
uuid.value.toString();
const { t } = useI18n({
  messages: {
    en: {
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    nl: {
      confirm: 'Bevestigen',
      cancel: 'Annuleren',
    },
  },
});

const { on, off, emit: busEmit } = useBusHandler();

const isShown = ref(false);
const saving = ref(false);
const hideOnConfirmation = ref(true);
const text = ref('');
const confirmButtonText = ref('');
const cancelButtonText = ref('');
const busHandlerIds: Ref<Array<number>> = ref([]);

onMounted(() => {
  busHandlerIds.value.push(
    on('showConfirmation', (options) => {
      assertBusArgs(options);
      if (isShown.value === true) {
        throw new Error('Confirmation dialog is already being shown');
      }
      isShown.value = true;
      text.value = options.text;
      confirmButtonText.value = options.confirmButtonText ?? t('confirm');
      cancelButtonText.value = options.cancelButtonText ?? t('cancel');
      hideOnConfirmation.value = options.hideOnConfirmation ?? true;
    }),
  );
  busHandlerIds.value.push(
    on('hideConfirmation', () => {
      saving.value = false;
      isShown.value = false;
    }),
  );
});

onBeforeUnmount(() => {
  busHandlerIds.value.forEach((bId) => off(bId));
  busHandlerIds.value = [];
});

function choose(value: boolean) {
  if (hideOnConfirmation.value) {
    isShown.value = false;
  } else {
    saving.value = true;
  }
  busEmit('confirmationResult', value);
}
</script>
