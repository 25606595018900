<template>
  <v-dialog
    :scrim="false"
    persistent
    :model-value="true"
    width="300"
  >
    <v-progress-linear
      indeterminate
      color="primary"
      class="my-0"
      height="10"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import { useUUID } from '@web-ui-root/composables/uuid';

const { uuid } = useUUID();
uuid.value.toString();
</script>

<script lang="ts">
export default {
  name: 'LoadingDialog',
};
</script>
