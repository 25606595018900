import SDK from '@withthegrid/amp-sdk';
import { API_URL } from '@web-ui-root/helpers/vite';

type SDKModel = { sdk: SDK };

/**
 * Privately used singleton to avoid re-instiationg the client SDK everytime
 * we need it.
 */
const sdk = new SDK(API_URL, true);

export function useSDK(): SDKModel {
  return {
    sdk,
  };
}
