import { type App } from 'vue';
import { useBusHandler } from '../composables/bus-handler';

/**
 * A simple plugin wrapper for the bus handler composable to keep the option
 * api defined components working
 */
export default {
  install: (app: App<unknown>): void => {
    const bus = useBusHandler();

    // for option syntax: this.$plugin
    app.config.globalProperties.$bus = bus;
    // for <script setup> syntax: inject('$plugin')
    app.provide('$bus', bus);
  },
};
