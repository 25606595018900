import { type RouteLocationNormalizedLoaded } from 'vue-router';
import { useUser } from '@web-ui-root/composables/user';
import { useEnvironment } from '@web-ui-root/composables/environment';
import { useIotEnvironment } from '@web-ui-root/composables/iot-environment';

const { isLoggedIn, hasRight: userHasRight } = useUser();
const { hasRight: monitoringHasRight } = useEnvironment();
const { hasRight: connectivityHasRight } = useIotEnvironment();

function hasAccess(route: RouteLocationNormalizedLoaded): boolean {
  const requiredRight: string | 'NONE' = route.matched.reduce((coll, val) => {
    if (
      val.meta !== undefined &&
      val.meta.requiredRight !== undefined &&
      typeof val.meta.requiredRight === 'string'
    ) {
      return val.meta.requiredRight;
    }
    return coll;
  }, 'NONE');

  if (requiredRight !== 'NONE') {
    if (isLoggedIn.value) {
      if (requiredRight === 'LOGGED_OUT') {
        return false;
      }
      if (requiredRight === 'LOGGED_IN') {
        return true;
      }

      if (userHasRight(requiredRight)) {
        return true;
      }

      if (route.matched.some((m) => m.meta.environment === 'asset')) {
        return monitoringHasRight(requiredRight);
      }

      if (route.matched.some((m) => m.meta.environment === 'deviceSupplier')) {
        return connectivityHasRight(requiredRight);
      }

      return false;
    }

    // !isLoggedIn.value
    if (requiredRight === 'LOGGED_OUT') {
      return true;
    }
    return false;
  }
  return true;
}

export default hasAccess;
