import { ref, type Ref } from 'vue';
import { version } from '../../package.json';
import { DEV } from '../helpers/vite';

export type LatestVersionModel = {
  checkVersion: () => Promise<boolean>;
  updateAvailable: Ref<boolean>;
};

const updateAvailable = ref(false);

export function useLatestVersion(): LatestVersionModel {
  let timer: ReturnType<typeof setTimeout>;

  async function checkVersion(): Promise<boolean> {
    if (DEV) {
      return false;
    }
    if (timer !== undefined) {
      clearTimeout(timer);
    }
    try {
      const response = await fetch(`${window.location.origin}/release.json`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          // can be a 304, prefer to force fetch, it's a small resource anyways
          // trying to reduce the number of times it can happen
          'Cache-Control': 'no-cache',
        },
      }).catch((error) => {
        console.error('error while checking the app version');
        console.error(error);
        // try again in 5 min
        setTimeout(checkVersion, 5 * 60 * 1000);
      });
      // this is this error code that throws a
      // 'Importing a module script failed.' on Safari (iOS)
      if (response === undefined) {
        updateAvailable.value = true;
        return updateAvailable.value;
      }
      if (response.status === 304) {
        updateAvailable.value = false;
      } else {
        const data = await response.json();
        if (data.version !== undefined) {
          updateAvailable.value = data.version !== version;
        }
      }
      timer = setTimeout(checkVersion, 30 * 60 * 1000);
    } catch (error) {
      console.error('error while checking the app version');
      console.error(error);
      // try again in 5 min
      setTimeout(checkVersion, 5 * 60 * 1000);
    }
    return updateAvailable.value;
  }

  return { checkVersion, updateAvailable };
}
