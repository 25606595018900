<template>
  <router-view @update:model-value="(val: boolean) => emit('update:modelValue', val)" />
</template>

<script setup lang="ts">
import { useUUID } from '../composables/uuid';

const { uuid } = useUUID();
uuid.value.toString();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();
</script>

<script lang="ts">
export default {
  name: 'EmptyRouter',
};
</script>
