import { isReactive, toRaw } from 'vue';
import cloneDeep from 'lodash-es/cloneDeep';

function camelToDash(str: string): string {
  return str.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
}

function dashOrSnakeToCamel(str: string): string {
  return str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  );
}

function dashOrSnakeToCamelObject<T extends object, K extends keyof T>(obj: T): T {
  const newObj = {} as T;

  Object.keys(obj).forEach((key: string) => {
    newObj[dashOrSnakeToCamel(key) as K] = obj[key as K];
  });

  return newObj;
}

/**
 * Cannot simply use JSON.stringify, as it meddles with the order of keys
 */
function isDeepEqual(o: any, p: any, onlyForPropertiesOfO = false): boolean {
  if (o === null || p === null) {
    // as typeof null === 'object'
    return o === p;
  }

  if (typeof o !== typeof p) {
    return false;
  }

  if (typeof o === 'object') {
    // Also covers array
    const oLength = Object.keys(o).length;
    const pLength = Object.keys(p).length;

    if (oLength !== pLength && !onlyForPropertiesOfO) {
      return false;
    }

    const oValue = o.valueOf();
    const pValue = p.valueOf();
    if (typeof oValue !== 'object') {
      // object without enumerable properties, like Date objects
      return oValue === pValue;
    }

    return Object.entries(o).every(([key, value]) => isDeepEqual(value, p[key]));
  }

  if (typeof o === 'function') {
    return true; // Do not check functions
  }

  return o === p;
}

function isObject(value: unknown) {
  return value !== null && !Array.isArray(value) && typeof value === 'object';
}

function getRawData<T>(data: T): T {
  return isReactive(data) ? toRaw(data) : data;
}

const keys = Object.keys as <T>(obj: T) => Array<keyof T>;

function toDeepRaw<T>(data: T): T {
  const rawData = getRawData(data);

  keys(rawData).forEach((key) => {
    const value: unknown = rawData[key];

    if (isObject(value) || Array.isArray(value)) {
      (rawData as Record<keyof T, unknown>)[key] = toDeepRaw(value);
    }
  });

  return rawData;
}

function safeJSONParse<T>(json?: string | null): T | undefined {
  if (json === undefined || json === null) {
    return undefined;
  }

  try {
    return JSON.parse(json) as T;
  } catch (e) {
    return undefined;
  }
}

type Refinement<A, B extends A> = {
  (a: A): a is B;
};

type Separated<T, U> = {
  left: Array<T>;
  right: Array<U>;
};

function partition<T, U extends T>(array: Array<T>, predicate: Refinement<T, U>): Separated<T, U> {
  return array.reduce(
    (acc, cur) => {
      if (predicate(cur)) {
        acc.right.push(cur);
      } else {
        acc.left.push(cur);
      }
      return acc;
    },
    { left: new Array<T>(), right: new Array<U>() },
  );
}

// not 100% I wrote it properly
// function partitionMap<T, U extends T, A, B>(
//   array: Array<T>,
//   predicate: Refinement<T, U>,
//   mapLeft: (a: T) => A,
//   mapRight: (a: T) => B,
// ): Separated<A, B> {
//   return array.reduce(
//     (acc, cur) => {
//       if (predicate(cur)) {
//         acc.right.push(mapRight(cur));
//       } else {
//         acc.left.push(mapLeft(cur));
//       }
//       return acc;
//     },
//     {
//       left: new Array<A>(),
//       right: new Array<B>(),
//     },
//   );
// }

export {
  camelToDash,
  dashOrSnakeToCamel,
  dashOrSnakeToCamelObject,
  isDeepEqual,
  toDeepRaw,
  cloneDeep,
  safeJSONParse,
  partition,
  // partitionMap,
};
