import { type ComputedRef, computed, type Ref, ref, unref } from 'vue';
import { useLeftPanel } from './left-panel';
import { useIotEnvironment } from './iot-environment';
import { useEnvironment } from './environment';
import { useUser } from './user';

type Component = {
  title: string;
  uuid: string;
};
type Components = Array<Component>;

// View as domain "View", not as in "View" of MVC/MVVM
type ViewState = {
  loadedComponents: Ref<Components>;
};

// View as domain "View", not as in "View" of MVC/MVVM
export type ViewModel = {
  defaultRoute: ComputedRef<string>;
  title: ComputedRef<string>;
  viewTitle: ComputedRef<string | null>;

  loadComponent: (component: Component) => void;
  unloadComponent: (componentId: Component['uuid']) => void;
};

const { typeKey, title: leftPanelTitle, hashId: entityHashId } = useLeftPanel();

const { hashId: iotEnvironmentHashId, name: iotEnvironmentName } = useIotEnvironment();

const { hashId: monitoringEnvironmentHashId, name: monitoringEnvironmentName } = useEnvironment();

const { isLoggedIn, environmentType } = useUser();

const state: ViewState = {
  loadedComponents: ref([] satisfies Components),
};

export function useView(): ViewModel {
  const defaultRoute = computed(() => {
    if (!isLoggedIn.value) {
      return '/login';
    }

    if (environmentType.value === 'environment' && monitoringEnvironmentHashId.value !== null) {
      const path = `/e/${monitoringEnvironmentHashId.value}`;
      if (typeKey.value !== null) {
        return `${path}/${typeKey.value}/${entityHashId.value}`;
      }
      return path;
    }

    if (environmentType.value === 'supplier' && iotEnvironmentHashId.value !== null) {
      return `/i/${iotEnvironmentHashId.value}`;
    }

    return '/set-up';
  });

  const viewTitle = computed(() => {
    const loadedComponents = unref(state.loadedComponents);
    return loadedComponents.length > 0 ? loadedComponents[loadedComponents.length - 1].title : null;
  });

  const title = computed(() => {
    const titleParts = [];

    if (monitoringEnvironmentName.value !== null) {
      titleParts.push(monitoringEnvironmentName.value);
    }

    if (iotEnvironmentName.value !== null) {
      titleParts.push(iotEnvironmentName.value);
    }

    if (leftPanelTitle.value !== null) {
      titleParts.push(leftPanelTitle.value);
    }

    if (viewTitle.value !== null) {
      titleParts.push(viewTitle.value);
    }

    return titleParts.length === 0 ? 'Withthegrid' : titleParts.join(' | ');
  });

  function loadComponent(component: Component) {
    const existingComponentIndex = state.loadedComponents.value.findIndex(
      (el) => el.uuid === component.uuid,
    );
    if (existingComponentIndex !== undefined) {
      state.loadedComponents.value.splice(existingComponentIndex, 1, component);
      return;
    }
    state.loadedComponents.value.push(component);
  }

  function unloadComponent(componentId: Component['uuid']) {
    state.loadedComponents.value = state.loadedComponents.value.filter(
      ({ uuid }) => uuid !== componentId,
    );
  }

  return {
    defaultRoute,
    viewTitle,
    title,
    loadComponent,
    unloadComponent,
  };
}
