const memoryStorage: Record<string, string> = {};

// feature check
function isLocalStorageAvailable(): boolean {
  try {
    localStorage.setItem('feature-check', 'value');
    localStorage.removeItem('feature-check');
    return true;
  } catch (e) {
    return false;
  }
}

function getLocalStorageItem(key: string, isJson = false): any {
  let valueFromStorage;

  if (isLocalStorageAvailable()) {
    valueFromStorage = localStorage.getItem(key) ?? memoryStorage[key];
  } else {
    valueFromStorage = memoryStorage[key];
  }

  // per localStorage spec, it returns null when not found
  if ((valueFromStorage ?? null) === null) {
    return null;
  }

  if (isJson) {
    try {
      return JSON.parse(valueFromStorage);
    } catch (e) {
      return null;
    }
  }
  return valueFromStorage;
}

function getLocalStorageItemNotFromMemory(key: string): any {
  if (localStorage !== undefined && localStorage !== null) {
    return localStorage.getItem(key);
  }
  return null;
}

function setLocalStorageItem(key: string, value: any, isJson = false): void {
  const valueFromStorage = isJson ? JSON.stringify(value) : value;

  if (isLocalStorageAvailable()) {
    // Safari with privacy options will have localStorage
    // but won't let us write to it.
    localStorage.setItem(key, valueFromStorage);
  } else {
    // Android WebView might not have localStorage at all.
    memoryStorage[key] = valueFromStorage;
  }
}
function removeLocalStorageItem(key: string): void {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key);
  }
  delete memoryStorage[key];
}

export {
  getLocalStorageItem,
  getLocalStorageItemNotFromMemory,
  setLocalStorageItem,
  removeLocalStorageItem,
};
