import { type RouteRecordRaw } from 'vue-router';
import { lazyRouterViewLoader } from '../../helpers/lazy-load-view';

import EmptyRouterView from '../../components/empty-router-view.vue';

const CommandsList = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/commands/command-table.vue'),
);
const Command = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/commands/command-view.vue'),
);
const commandsRoute: Array<RouteRecordRaw> = [
  {
    path: 'commands',
    component: EmptyRouterView,
    children: [
      {
        path: '',
        component: CommandsList,
        props: (route) => ({ query: route.query }),
      },
      {
        path: ':hashId',
        component: Command,
        props: (route) => ({ hashId: route.params.hashId }),
      },
    ],
  },
];
export default commandsRoute;
