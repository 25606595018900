import { type ComponentOptions, createApp } from 'vue';

import { useEnvironment } from '@web-ui-root/composables/environment';
import { useUser } from '@web-ui-root/composables/user';
import { useView } from '@web-ui-root/composables/view';
import { useSDK } from '@web-ui-root/composables/sdk';

import { browsersRegex } from 'virtual:supported-browsers';
import { registerPlugins } from './plugins';
import router from './router';
import attachRouterToState from './router/sync';
import { humanDate } from './helpers/date-utils';

import { useRollbar } from './composables/rollbar';
import pushRoute from './router/push';
import App from './App.vue';
import { useLatestVersion } from './composables/latest-version';
import {
  getLocalStorageItemNotFromMemory,
  setLocalStorageItem,
} from './helpers/local-storage/local-storage';
import { MINUTE_IN_MS } from './helpers/constants/constants';
import Invariant from './helpers/invariant';

const outdatedBrowser = localStorage.getItem('outdatedBrowser');

/**
 * Check if the browser is supported, if not throw an error to prevent throwing errors in Rollbar
 * and prevent further executing of the app, also emit a event to display the oudated browser text.
 */
if (
  !browsersRegex.test(navigator.userAgent) ||
  (outdatedBrowser !== null && JSON.parse(outdatedBrowser) === true)
) {
  document.dispatchEvent(new Event('outdatedBrowser'));
  throw new Error('Unsupported browser');
}

const { updateSettings, resumeSession, hasRight: userHasRight } = useUser();
const { hasRight: envHasRight } = useEnvironment();
const { defaultRoute } = useView();
const { rollbar } = useRollbar();
const { sdk } = useSDK();
const { checkVersion } = useLatestVersion();

const app = createApp(App);

window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();

  const hasReloaded = localStorage.getItem('preLoadErrorHasReloaded');
  if (hasReloaded === null) {
    localStorage.setItem('preLoadErrorHasReloaded', '1');
  } else {
    let reloadedCount = parseInt(hasReloaded, 10);
    if (reloadedCount < 3) {
      reloadedCount += 1;
      localStorage.setItem('preLoadErrorHasReloaded', reloadedCount.toString());
    } else {
      console.error(event);
      throw new Error('Too many reloads due to preload error');
    }
  }
  window.location.reload();
});

/*
  First define own error handler, as Rollbar will call that after its own,
  see https://docs.rollbar.com/docs/vue-js
*/
app.config.errorHandler = async (err: unknown, _vm: unknown, info: string): Promise<void> => {
  const hasUpdate = await checkVersion();
  if (rollbar !== undefined && !hasUpdate) {
    const rollError =
      err instanceof Error
        ? err
        : new Error(typeof err === 'string' ? err : JSON.stringify(err, null, 2));
    try {
      rollbar.error(rollError);
    } catch (e) {
      console.error('Rollbar error', e);
    }
  }
  console.log(`Error source: ${info}`);
  console.error(err);
};

window.onunhandledrejection = (evt: PromiseRejectionEvent) => {
  if (app.config.errorHandler !== undefined) {
    app.config.errorHandler.call(this, evt.reason, null, evt.type);

    // prevent console logging, is already done in Vue.config.errorHandler
    evt.preventDefault();
  }
};

sdk.onServerVersionChanged(async () => {
  await updateSettings();
});

registerPlugins(app);

app.config.performance = true;

app.config.globalProperties.$passive = {};
app.config.globalProperties.$touch = matchMedia('(hover: none), (pointer: coarse)').matches;

const hasRight = (right: string) => userHasRight(right) || envHasRight(right);
const goToDefaultRoute = () => pushRoute(defaultRoute.value);
const globalMethodsMixin: ComponentOptions = {
  methods: {
    hasRight,
    goToDefaultRoute,
    pushRoute,
    humanDate,
  },
};
// these are just to provide the methods to components using the `<script setup` syntax
app.provide('hasRight', hasRight);
app.provide('goToDefaultRoute', goToDefaultRoute);
app.provide('pushRoute', pushRoute);
app.provide('humanDate', humanDate);

app.mixin(globalMethodsMixin);

app.mount('#app');

function versionCheckAfterInactivity(lastActivity: number | string): void {
  // the value comes from the local storage, so better check it
  const validLastActivity = (function iife(val) {
    if (typeof val === 'number') {
      return val;
    }
    let la;
    try {
      la = parseInt(val, 10);
    } catch (e) {
      throw new Invariant('provided last-activity value is not a number');
    }
    return la;
  })(lastActivity);

  const now = Date.now();
  const diff = now - validLastActivity;
  const fifteenMinutes = MINUTE_IN_MS * 15;

  if (diff > fifteenMinutes) {
    console.log('rollbar-log: the app has been forced refreshed after 15min of inactivity');
    setLocalStorageItem('last-activity', Date.now());
    window.location.reload();
  }
}

router
  .isReady()
  .then(async () => {
    await resumeSession(router.currentRoute.value);
    await attachRouterToState();

    const lastActivity = getLocalStorageItemNotFromMemory('last-activity');
    if (lastActivity === null) {
      setLocalStorageItem('last-activity', Date.now());
    } else {
      // we have a version check set after 30mins in the App.vue (so after 1st load)
      // but for mobile devices (mostly, but also valid on desktop, especially for
      // macos user where putting to sleep is way more common than closing/shutdown)
      // the app might be taken out of sleep after a long time and be out of date
      // In this case we can perform a version check pro-actively
      versionCheckAfterInactivity(lastActivity);
    }
  })
  .catch((err) => {
    console.error(err);
    throw err;
  });
