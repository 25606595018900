/**
 * inspired by https://github.com/mapbox/mapbox-gl-draw/blob/master/src/lib/throttle.js
 */
class Throttle {
  /**
   *
   * @param {Number} time refresh interval
   */
  constructor(time = 16) {
    this.time = time;
    this.lock = false;
    this.throttledFunc = undefined;
  }

  run(func) {
    if (this.lock) {
      // called too soon, queue to call later
      this.throttledFunc = func;
    } else {
      // lock until later then call
      this.lock = true;
      setTimeout(() => {
        func();

        setTimeout(() => {
          // reset lock and call if queued
          this.lock = false;
          if (this.throttledFunc !== undefined) {
            this.throttledFunc();
            this.throttledFunc = undefined;
          }
        }, this.time);
      }, 0);
    }
  }

  stop() {
    this.throttledFunc = undefined;
  }
}

export default Throttle;
