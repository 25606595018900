/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Types
import type { App } from 'vue';

// Plugins
import router from '../router';
import vuetify from './vuetify';
import i18n from './vue-i18n';
import sdk from './wtg-sdk';
import confirm from './confirm';
import busHandler from './bus-handler';

export function registerPlugins(app: App): void {
  app
    .use(vuetify)
    .use(router)
    .use(i18n)
    // I added thes one as a pluging for feature parity, but it should not be necessary
    // as we can simply use the composable starting from now.
    // But it's needed for all components/views defined with the options API that use this globally.
    .use(sdk)
    .use(busHandler)
    .use(confirm);
}
