import { type RouteRecordRaw } from 'vue-router';
import EmptyRouterView from '@web-ui-root/components/empty-router-view.vue';
import { lazyRouterViewLoader } from '../helpers/lazy-load-view';
import supplierAuthenticationRoute from './supplier/authentication';
import commandsRoute from './supplier/commands';

const IoTFramework = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/connectivity-framework.vue'),
);

const SettingsEnvironment = lazyRouterViewLoader(
  () =>
    import('@web-ui-root/views/connectivity/settings/environment/environment-settings-form.vue'),
);
const UsersList = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/settings/users/user-settings-table.vue'),
);
const User = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/settings/users/user-settings-form.vue'),
);
const AuditLog = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/audit-log-table.vue'),
);
const SupplierActivities = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/supplier-activities/supplier-activity-table.vue'),
);
const SupplierActivity = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/supplier-activities/supplier-activity-view.vue'),
);
const DeviceTypes = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/device-types/device-type-table.vue'),
);
const DeviceType = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/device-types/device-type-form.vue'),
);
const DeviceTypeFieldConfigurations = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/connectivity/device-types/device-type-field-configurations-list.vue'
    ),
);
const DeviceTypePinGroupFieldConfigurations = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/connectivity/device-types/device-type-pin-group-field-configurations-list.vue'
    ),
);
const DeviceTypeEventHandler = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/device-types/device-type-event-handler-editor.vue'),
);
const ReportTypes = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/report-types/report-type-table.vue'),
);
const ReportType = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/report-types/report-type-form.vue'),
);
const ReportTypeParser = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/report-types/report-type-parser-editor.vue'),
);
const CommandTypes = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/command-types/command-type-table.vue'),
);
const CommandType = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/command-types/command-type-view.vue'),
);
const Credentials = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/credentials/credential-table.vue'),
);
const Credential = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/credentials/credential-view.vue'),
);
const Quantities = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/quantities/quantity-table.vue'),
);
const Quantity = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/quantities/quantity-form.vue'),
);
const IoTDevices = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/devices/device-table.vue'),
);
const IoTDevice = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/devices/device-view.vue'),
);

const connectivityEnvironmentRoutes: RouteRecordRaw[] = [
  {
    path: '/i/:environmentHashId',
    component: IoTFramework,
    meta: { requiredRight: 'ENVIRONMENT_ADMIN', environment: 'deviceSupplier' },
    children: [
      {
        path: '',
        redirect: { name: 'supplierActivities' },
      },
      {
        path: 'settings',
        component: EmptyRouterView,
        children: [
          {
            path: 'environment',
            component: SettingsEnvironment,
          },
          {
            path: 'users',
            component: EmptyRouterView,
            children: [
              {
                path: '',
                component: UsersList,
                props: (route) => ({ query: route.query }),
              },
              {
                path: 'user/:hashId?',
                component: User,
                props: true,
              },
            ],
          },
          {
            path: 'audit-log',
            name: 'auditLogIot',
            component: AuditLog,
            props: (route) => ({ query: route.query }),
          },
        ],
      },
      {
        name: 'supplierActivities',
        path: 'activity',
        component: SupplierActivities,
        props: (route) => ({ query: route.query }),
      },
      {
        path: 'activity/:hashId',
        component: SupplierActivity,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'device-types',
        component: DeviceTypes,
        props: (route) => ({ query: route.query, search: route.query.search }),
      },
      {
        path: 'device-types/:hashId',
        component: DeviceType,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'device-types/:deviceTypeHashId/form-fields',
        component: DeviceTypeFieldConfigurations,
        props: (route) => ({ deviceTypeHashId: route.params.deviceTypeHashId }),
      },
      {
        path: 'device-types/:deviceTypeHashId/pin-group-form-fields',
        component: DeviceTypePinGroupFieldConfigurations,
        props: (route) => ({ deviceTypeHashId: route.params.deviceTypeHashId }),
      },
      {
        path: 'device-types/:deviceTypeHashId/event-handler',
        component: DeviceTypeEventHandler,
        props: (route) => ({ deviceTypeHashId: route.params.deviceTypeHashId }),
      },
      {
        path: 'report-types',
        component: ReportTypes,
        props: (route) => ({ query: route.query, search: route.query.search }),
      },
      {
        path: 'report-types/:hashId',
        component: ReportType,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'command-types',
        component: CommandTypes,
        props: (route) => ({ query: route.query, search: route.query.search }),
      },
      {
        path: 'command-types/:hashId',
        component: CommandType,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'credentials',
        component: Credentials,
        props: (route) => ({ query: route.query, search: route.query.search }),
      },
      {
        path: 'credentials/:hashId',
        component: Credential,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'devices',
        component: IoTDevices,
        props: (route) => ({ query: route.query, search: route.query.search }),
      },
      {
        path: 'devices/:hashId',
        component: IoTDevice,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'quantities',
        component: Quantities,
        props: (route) => ({ query: route.query }),
      },
      {
        path: 'quantities/:hashId',
        component: Quantity,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'report-types/:reportTypeHashId/parser',
        component: ReportTypeParser,
        props: (route) => ({ reportTypeHashId: route.params.reportTypeHashId }),
      },
      ...supplierAuthenticationRoute,
      ...commandsRoute,
    ],
  },
];

export default connectivityEnvironmentRoutes;
