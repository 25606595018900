<template>
  <v-app
    :key="locale"
    style="background-color: #f5f5f5"
    class="custom-scrollbar"
  >
    <router-view @input="(show: boolean) => toggleRouterView(show)" />

    <confirm />

    <v-snackbar
      v-model="updateAvailable"
      color="warning"
      class="center"
      location="top"
      :timeout="-1"
      z-index="2500"
    >
      <span v-t="'newVersionAvailable'" />
      <template #actions>
        <v-btn
          theme="dark"
          variant="text"
          @click="updateVersion()"
        >
          <span v-t="'updateNow'" />
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      class="center"
      :timeout="-1"
    >
      {{ snackbar.text }}
      <template
        v-if="snackbar.action !== undefined"
        #actions
      >
        <v-btn
          theme="dark"
          variant="text"
          @click="snackbar.action.callback"
        >
          {{ snackbar.action.text }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-spacer />

    <v-footer
      v-if="!isMap && mdAndUp"
      class="pa-4"
      style="position: static; max-height: 4rem; background: transparent"
    >
      <v-spacer />
      <div
        v-if="logo === null"
        class="logo"
      >
        withthegrid
      </div>
    </v-footer>
  </v-app>
</template>

<script lang="ts" setup>
import { ref, computed, watch, inject, onMounted, onBeforeUnmount, type Ref } from 'vue';
import { useRouter } from 'vue-router';
import { useDisplay, useTheme } from 'vuetify';
import { useI18n } from 'vue-i18n';

import { models, errors as sdkErrors } from '@withthegrid/amp-sdk';

import { useView } from '@web-ui-root/composables/view';
import { useUser } from '@web-ui-root/composables/user';
import { useUUID } from '@web-ui-root/composables/uuid';
import { type RouteLocationRaw } from 'vue-router';
import { useEnvironment } from '@web-ui-root/composables/environment';
import { useIotEnvironment } from '@web-ui-root/composables/iot-environment';
import { useBusHandler } from './composables/bus-handler';
import { type ColorKeys, ThemeManager } from './helpers/theme';
import Invariant from './helpers/invariant';

import Confirm from './components/dialogs/confirm-dialog.vue';
import { useLatestVersion } from './composables/latest-version';

type Locale = models.locale.Locale;

type Option<T> = T | undefined;
type Callback = () => void;

const localMessages = {
  en: {
    newVersionAvailable: 'A new version is available.',
    updateNow: 'Refresh',
    unknownError: 'Withthegrid has automatically been informed of an unexpected error',
    updateError:
      'A error occurred while a update is available. Please refresh the page to continue.',
    updateAvailable: 'There is an update available. Refresh to continue',
    tooManyRequests: 'You have performed too many requests to the server. Try again soon.',
    requestedResourceNotFound: 'The requested resource cannot be found.',
    forbidden: 'Your are not authorized to perform the requested action',
    requestFailed:
      'The server cannot be reached. Check status.withthegrid.com to identify whether this is caused by withthegrid or your browser/ connection',
    noAccess: 'You have no access to the requested object',
    noSecondaryConnection:
      "A database upgrade is on its way. We can't accept analytics queries during this time. Please wait a few minutes and try again.",
    pinLimit:
      'You have reached the allowed number of location ports. Contact sales to increase this limit.',
    expired:
      'This environment has expired. You cannot make any changes to it. Contact sales to acquire a (new) license.',
    logInWith2FA:
      'Two factor authentication has been enabled for your account. Please log in again.',
    logInAgain: 'Your session is no longer valid. Please log in again to continue.',
    removed: '{0} has been removed',
    updated: '{0} has been updated',
    added: '{0} has been added',
    undo: 'Undo',
    close: 'Close',
    fileTooLarge: 'The file {0} is too large. Maximum file size is {1}MB',
    requestTooLarge: 'The request is too large. Maximum request size is 25MB',
  },
  nl: {
    newVersionAvailable: 'Er is een nieuwe versie beschikbaar.',
    updateNow: 'Ververs',
    unknownError: 'Withthegrid is automatisch op de hoogste gesteld van een onverwachte fout.',
    updateErrror:
      'Er is een fout opgetreden terwijl een update beschikbaar is. Ververs de pagina om door te gaan.',
    updateAvailable: 'Er is een update beschikbaar. Ververs om door te gaan',
    tooManyRequests:
      'Je hebt teveel HTTP verzoeken aan de server gedaan. Probeer het binnenkort weer.',
    requestedResourceNotFound: 'Het gevraagde object kan niet woren gevonden.',
    forbidden: 'Je bent niet geautoriseerd om de gevraagde actie uit te voeren',
    requestFailed:
      'De server van withthegrid kan niet worden bereikt. Controleer op status.withthegrid.com of dit aan jouw browser/ internetverbinbding ligt, of dat de oorzaak bij withthegrid ligt',
    noAccess: 'Je hebt geen toegang tot het gevraagde object',
    noSecondaryConnection:
      'Een database upgrade wordt uitgevoerd. We kunnen daarom kortstondig geen analyse queries uitvoeren. Wacht alstublieft een paar minuten en probeer het nogmaals.',
    pinLimit:
      'Je hebt de limiet op het aantal locatie-poorten bereikt. Neem contact op met sales om de limiet te verhogen.',
    expired:
      'Deze omgeving is verlopen. Je kan geen wijzigingen meer doorvoeren. Neem contact op met sales voor een nieuwe licentie.',
    logInWith2FA: 'Tweefactorauthenticatie is ingeschakeld voor jouw account. Log opnieuw in.',
    logInAgain: 'Je sessie is niet langer geldig. Log opnieuw in om door te gaan.',
    removed: '{0} is verwijderd',
    updated: '{0} is bijgewerkt',
    added: '{0} is toegevoegd',
    undo: 'Ongedaan maken',
    close: 'Sluiten',
    fileTooLarge: 'Het bestand {0} is te groot. De maximale bestandsgrootte is {1}MB',
    requestTooLarge: 'Het verzoek is te groot. De maximale bestandsgrootte is 25MB',
  },
};

const vuetifyTheme = useTheme();
const { locale, updateSettings, isLoggedIn, logOut } = useUser();
const { off, emit, on } = useBusHandler();

const { uuid } = useUUID();
uuid.value.toString();
const { title } = useView();
const { mdAndUp } = useDisplay();
const { locale: i18nLocale, t } = useI18n({
  useScope: 'global',
  messages: localMessages,
});
const router = useRouter();
const { checkVersion, updateAvailable } = useLatestVersion();
const { logo: monitoringEnvironmentLogo } = useEnvironment();
const { logo: connectivityEnvironmentLogo } = useIotEnvironment();

const goToDefaultRoute: Option<() => void> = inject('goToDefaultRoute');
const pushRoute: Option<(location: RouteLocationRaw) => Promise<boolean>> = inject('pushRoute');

type SnackbarAction = {
  text: string;
  callback: Callback;
};
type SnackBar = {
  action?: SnackbarAction;
  color: keyof ColorKeys;
  show: boolean;
  timeoutId: null | number;
  text: string;
};
const snackbar = ref<SnackBar>({
  action: undefined,
  color: 'info',
  show: false,
  timeoutId: null,
  text: '',
});

const lastSnackbarId = ref(-1);
const busHandlerIds: Ref<Array<number>> = ref([]);

const isMap = computed(() => router.currentRoute.value.matched.some((m) => m.meta?.isMap === true));

const logo = computed(() => monitoringEnvironmentLogo.value ?? connectivityEnvironmentLogo.value);

watch(locale, (val) => {
  setLocale(val);
});

watch(title, (val) => {
  document.title = val;
});

watch(updateAvailable, () => {
  if (!updateAvailable.value) {
    updateSettings();
  }
});

function initTheme() {
  const themeManager = ThemeManager.getInstance();
  const root: HTMLElement | null = document.querySelector(':root');
  const setCSSVariables = (newTheme: ColorKeys) => {
    Object.entries(newTheme).forEach(([key, value]) => {
      if (root !== null) {
        root.style.setProperty(`--theme-color-${key}`, value);
      }
    });
  };

  themeManager.onThemeChange((newTheme) => {
    type VuetifyColors = (typeof vuetifyTheme.themes.value)[string]['colors'];
    const colors: Record<string, string> = {};
    Object.entries(vuetifyTheme.global.current.value.colors).forEach(([key, value]) => {
      if (key.startsWith('on-')) {
        return; // Skip the iteration if key starts with 'on-'
      }
      colors[key] = value;
    });
    Object.entries(newTheme.light).forEach(([key, value]) => {
      colors[key] = value;
    });
    vuetifyTheme.themes.value.userTheme = {
      dark: vuetifyTheme.global.current.value.dark,
      variables: vuetifyTheme.global.current.value.variables,
      colors: colors as VuetifyColors,
    };
    vuetifyTheme.global.name.value = 'userTheme';
    setCSSVariables(newTheme.light);
  });

  setCSSVariables(themeManager.theme.light);
}

function hideSnackbar() {
  if (snackbar.value.timeoutId !== null) {
    window.clearTimeout(snackbar.value.timeoutId);
    snackbar.value.timeoutId = null;
  }
  snackbar.value.show = false;
}

function showSnackbar(
  color: keyof ColorKeys,
  message: string,
  actionCallback?: () => void,
  timeout = 6000,
  action: 'close' | 'undo' = 'close',
) {
  hideSnackbar();

  lastSnackbarId.value += 1;

  const snackbarActions: Record<string, SnackbarAction> = {
    close: {
      text: t('close'),
      callback: () => {
        if (typeof actionCallback === 'function') {
          actionCallback();
        }
        hideSnackbar();
      },
    },
    undo: {
      text: t('undo'),
      callback: () => {
        if (typeof actionCallback === 'function') {
          actionCallback();
        }
        hideSnackbar();
      },
    },
  };

  const snackbarId = lastSnackbarId.value;

  snackbar.value.text = message;
  snackbar.value.color = color;
  snackbar.value.action = snackbarActions[action];

  if (timeout > 0) {
    snackbar.value.timeoutId = window.setTimeout(() => {
      snackbar.value.timeoutId = null;
      hideSnackbar();
    }, timeout);
  }
  snackbar.value.show = true;

  const hideCallback = () => {
    if (snackbarId === lastSnackbarId.value) {
      hideSnackbar();
    }
  };
  return hideCallback;
}

function updateVersion() {
  // Reload the current page, without using the cache
  window.location.reload();
}

function setLocale(newLocale: Locale) {
  i18nLocale.value = newLocale;
}

function toggleRouterView(show: boolean) {
  if (!show && goToDefaultRoute !== undefined) {
    goToDefaultRoute();
  }
}

type SnackbarArgsTuple = [string, Option<Callback>, Option<number>, Option<'close' | 'undo'>];

/**
 * Type narrows the snackbar arguments
 */
function assertSnackbarArgs(input: unknown[]): asserts input is SnackbarArgsTuple {
  Invariant.assert(input.length > 0, 'showSnackbar was called with less args than required', input);
  Invariant.assert(
    typeof input[0] === 'string',
    'showSnackbar 1st argument is not a string',
    input,
  );

  if (input.length >= 2) {
    Invariant.assert(
      typeof input[1] === 'function' || input[1] === undefined,
      'showSnackbar 2nd argument is not a function',
      input,
    );
  }

  if (input.length >= 3) {
    Invariant.assert(
      typeof input[2] === 'number',
      'showSnackbar was called with a non-number timeout',
      input,
    );
  }

  if (input.length >= 4) {
    Invariant.assert(
      input[3] === 'close' || input[3] === 'undo',
      'showSnackbar was called with an invalid action',
      input,
    );
  }
}

function onCrudEvent(eventName: string, ...args: SnackbarArgsTuple) {
  const [objectName, actionCallback] = args;
  const truncatedObjectName = `${objectName.slice(0, 50)}${objectName.length > 50 ? '...' : ''}`;
  showSnackbar(
    'info',
    t(eventName, [truncatedObjectName]),
    actionCallback,
    6000,
    actionCallback !== undefined ? 'undo' : undefined,
  );
}

onBeforeUnmount(() => {
  busHandlerIds.value.forEach((busHandlerId) => {
    off(busHandlerId);
  });
  busHandlerIds.value = [];
});

const onError = (...args: unknown[]) => {
  assertSnackbarArgs(args);
  return showSnackbar('error', ...args);
};

const onWarning = (...args: unknown[]) => {
  assertSnackbarArgs(args);
  return showSnackbar('warning', ...args);
};

const onInfo = (...args: unknown[]) => {
  assertSnackbarArgs(args);
  return showSnackbar('info', ...args);
};

const onSuccess = (...args: unknown[]) => {
  assertSnackbarArgs(args);
  return showSnackbar('success', ...args);
};

const onErrorReported = () =>
  showSnackbar('error', updateAvailable.value ? t('updateError') : t('unknownError'));

const partialOnCrudEvent =
  (eventName: string) =>
  (...args: unknown[]) => {
    assertSnackbarArgs(args);
    onCrudEvent(eventName, ...args);
  };

onMounted(() => {
  initTheme();

  busHandlerIds.value.push(
    on('error', onError),
    on('warning', onWarning),
    on('info', onInfo),
    on('success', onSuccess),
    on('errorReported', onErrorReported),

    on('removed', partialOnCrudEvent('removed')),
    on('added', partialOnCrudEvent('added')),
    on('updated', partialOnCrudEvent('updated')),

    on('commsError', async (error, ...args) => {
      /*
        comms errors should not be reported to Rollbar, as they are either
        intentional (http status < 500) or already logged server side (http status >= 500)
      */
      if (error instanceof sdkErrors.Parsing) {
        emit('error', error.details[0].message, ...args);
        console.log(error.details);
        console.log(error.stack);
        return;
      }
      if (error instanceof sdkErrors.FileTooLarge) {
        emit('error', t('fileTooLarge', [error.filename, error.maxSizeMB]));
        return;
      }
      if (error instanceof sdkErrors.RequestTooLarge) {
        emit('error', t('requestTooLarge'));
        return;
      }
      if (error instanceof sdkErrors.CommsCanceled) {
        /*
          do nothing: this is probably caused by the interceptor in sdk
          which can cancel a request if an environment change is required. If the catch code
          of the original request emits a commsError, it will end up here.
        */
        return;
      }
      if (error instanceof sdkErrors.OutdatedClient) {
        emit('error', t('updateAvailable'), ...args);
        return;
      }
      if (error instanceof sdkErrors.Billing) {
        if (error.key === 'pin_limit') {
          emit('error', t('pinLimit'), ...args);
          return;
        }
        if (error.key === 'expired') {
          emit('error', t('expired'), ...args);
          return;
        }
      }
      if (error instanceof sdkErrors.CommsResponse) {
        if (error.status < 500) {
          if (error.data !== undefined && error.data.message !== undefined) {
            emit('error', error.data.message, ...args);
            return;
          }
          if (error.status === 429) {
            emit('error', t('tooManyRequests'), ...args);
            return;
          }
          if (error.status === 403) {
            emit('error', t('forbidden'), ...args);
            return;
          }
          emit('error', t('requestedResourceNotFound'), ...args);
          return;
        }

        console.log(JSON.stringify(error, null, 2));
        emit('errorReported', ...args);
        return;
      }
      if (error instanceof sdkErrors.CommsRequest) {
        emit('error', t('requestFailed'), ...args);
        return;
      }
      if (error instanceof sdkErrors.NoSecondaryConnection) {
        emit('error', t('noSecondaryConnection'), ...args);
        return;
      }
      if (error instanceof sdkErrors.Authentication) {
        if (isLoggedIn.value) {
          if (error.key === '2fa_required' || error.key === '2fa_required_for_admins') {
            if (pushRoute !== undefined) {
              await pushRoute({ path: '/two-factor-authentication-required' });
              return;
            }
          } else if (error.key === '2fa_missing_in_jwt') {
            emit('error', t('logInWith2FA'), ...args);
            if (pushRoute !== undefined) {
              await logOut();
              await pushRoute({ path: '/login' });
              return;
            }
          } else if (
            [
              'jwt_missing',
              'jwt_expired',
              'jwt_failed',
              'jwt_missing_hashId',
              'invalid_session',
            ].includes(error.key)
          ) {
            emit('error', t('logInAgain'), ...args);
            await logOut();
            if (pushRoute !== undefined) {
              await pushRoute({ path: '/login' });
              return;
            }
          } else {
            if (router.currentRoute.value.name === 'enable-2fa' && error.key === 'invalid_code') {
              // ignore because it's handled by the component
              // but bc. of a race condition, this warning is being shown
              return;
            }
            emit('error', t('noAccess'), ...args);
            // do not always log out user when he gets an auth error
            // this.logOut();
          }
        }
        // ignore the error if logged out
        return;
      }

      throw error; // To be caught by Rollbar
    }),
    on('checkVersion', checkVersion),
  );

  setTimeout(
    () => {
      checkVersion();
    },
    30 * 60 * 1000,
  );

  setLocale(locale.value);
});
</script>

<style scoped>
.logo {
  text-align: center;
  font-family: with-the-grid;
  font-size: 20px;
}
</style>

<style>
@import './global.css';

/* env framwork navbar styles */
.left-sidebar .v-list-item .v-list-item-title {
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 87);
}

.left-sidebar .v-list-item .v-list-item__prepend {
  color: rgba(0, 0, 0, 87);
  font-size: 16px;
}

.left-sidebar .v-list-item--active .v-list-item-title,
.left-sidebar .v-list-item--active .v-list-item__prepend {
  color: rgb(var(--v-theme-primary));
}
</style>
