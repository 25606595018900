/* eslint-disable import/extensions */
/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles/main.css';
/* eslint-disable import/no-unresolved */
import { mdi as mdiSvg } from 'vuetify/iconsets/mdi-svg';
import { aliases as mdiAliases, mdi } from 'vuetify/iconsets/mdi';
/* eslint-enable import/no-unresolved */

// Composables
import { createVuetify } from 'vuetify';

import { THEMES, ThemeManager } from '@web-ui-root/helpers/theme';

const themeManager = ThemeManager.getInstance(THEMES.Withthegrid);

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'wtg',
    themes: {
      wtg: {
        colors: themeManager.theme.light as Record<string, string>,
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: mdiAliases,
    sets: {
      mdi,
      mdiSvg,
    },
  },
});
