import { ref, type Ref } from 'vue';

type UUIDModel = {
  uuid: Ref<string>;
};

let internalUuid = 0;

export function useUUID(): UUIDModel {
  const uuid = ref(internalUuid.toString());

  internalUuid += 1;

  return { uuid };
}
