import { type App } from 'vue';
import { useConfirm } from '../composables/confirm';

export default {
  install: (app: App<unknown>): void => {
    const { confirm } = useConfirm();

    // for option syntax: this.$plugin
    app.config.globalProperties.$confirm = confirm;
    // for <script setup> syntax: inject('$plugin')
    app.provide('$confirm', confirm);
  },
};
