import { isDeepEqual } from './object-helper';

type Predicate = (() => boolean) | boolean;

/**
 * # Invariant errors
 *
 * Used to assert and throw invariant errors.
 * see [ref](https://en.wikipedia.org/wiki/Invariant-based_programming#:~:text=Invariant%2Dbased%20programming%20is%20a,before%20the%20actual%20program%20statements.)
 *
 * This is pretty simple, when a developper can make an error, and invariant check
 * can be performed to throw informed errors.
 *
 * The constructor is a simple log and throw.
 *
 * But some static helpers are present for some common use cases.
 */
export default class Invariant extends Error {
  /**
   * Throws an InvariantError with a custom message, and dumping some data if provided
   */
  constructor(reason: string, data?: unknown) {
    super(reason);
    this.name = 'InvariantError';

    if (data !== undefined) {
      console.error(typeof data === 'object' ? JSON.stringify(data, null, 2) : data);
    }
  }

  /**
   * Performs an assertion on the provided boolean or predicate and decides or not
   * to throw an InvariantError.
   */
  public static assert(predicate: Predicate, reason?: string, data?: unknown): void {
    const result = typeof predicate === 'function' ? predicate() : predicate;

    if (!result) {
      throw new Invariant(reason ?? 'Invariant assertion failed', data);
    }
  }

  /**
   * Performs a deep equality assertion on the two provided variables and decides or not
   * to throw an InvariantError.
   *
   * Also logs the two variables in case of failure
   */
  public static assertEq(a: unknown, b: unknown, reason?: string): void {
    const result = isDeepEqual(a, b);

    if (!result) {
      console.error({ a, b });
      throw new Invariant(reason ?? 'Invariant equality assertion failed');
    }
  }

  /**
   * Performs a deep equality assertion on the two provided variables and decides or not
   * to throw an InvariantError.
   *
   * Also logs the two variables in case of failure
   */
  public static assertNotEq(a: unknown, b: unknown, reason?: string): void {
    const result = !isDeepEqual(a, b);

    if (!result) {
      console.error({ a, b });
      throw new Invariant(reason ?? 'Invariant !equality assertion failed');
    }
  }

  /**
   * Asserts that the provided value is not undefined
   */
  public static assertDefined<T>(value: T | undefined, reason?: string): asserts value is T {
    if (value === undefined) {
      throw new Invariant(reason ?? 'Invariant defined assertion failed');
    }
  }

  /**
   * Asserts that the provided value is not undefined nor null
   */
  public static assertPresent<T>(value: T | undefined | null, reason?: string): asserts value is T {
    if (value === undefined || value === null) {
      throw new Invariant(reason ?? 'Invariant present assertion failed');
    }
  }

  public static todo(reason: string): void {
    throw new Invariant(`TODO: ${reason}`);
  }

  public static unreachable(): void {
    throw new Invariant('Supposedly unreachable code branch was reached.');
  }
}
