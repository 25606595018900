import { type RouteRecordRaw } from 'vue-router';
import { lazyRouterViewLoader } from '../../helpers/lazy-load-view';

import EmptyRouterView from '../../components/empty-router-view.vue';

const CertificateTable = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/device-access/certificates/certificate-table.vue'),
);
const CertificateForm = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/device-access/certificates/certificate-form.vue'),
);
const CertificateIdentifierEditor = lazyRouterViewLoader(
  () =>
    import(
      '@web-ui-root/views/connectivity/device-access/certificates/certificate-identifier-editor.vue'
    ),
);
const WebhookTable = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/device-access/webhooks/webhook-table.vue'),
);
const WebhookForm = lazyRouterViewLoader(
  () => import('@web-ui-root/views/connectivity/device-access/webhooks/webhook-form.vue'),
);
const WebhookIdentifierEditor = lazyRouterViewLoader(
  () =>
    import('@web-ui-root/views/connectivity/device-access/webhooks/webhook-identifier-editor.vue'),
);

const authenticationRoute: Array<RouteRecordRaw> = [
  {
    path: 'device-access',
    // legacy path for backwards compatibility
    alias: ['authentication'],
    component: EmptyRouterView,
    children: [
      {
        path: 'certificates',
        component: CertificateTable,
        props: (route) => ({ query: route.query }),
      },
      {
        path: 'certificates/:hashId',
        component: CertificateForm,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'certificates/:certificateHashId/identifier',
        component: CertificateIdentifierEditor,
        props: (route) => ({ certificateHashId: route.params.certificateHashId }),
      },
      {
        path: 'webhooks',
        component: WebhookTable,
        props: (route) => ({ query: route.query }),
      },
      {
        path: 'webhooks/:hashId',
        component: WebhookForm,
        props: (route) => ({ hashId: route.params.hashId }),
      },
      {
        path: 'webhooks/:webhookHashId/identifier',
        component: WebhookIdentifierEditor,
        props: (route) => ({ webhookHashId: route.params.webhookHashId }),
      },
    ],
  },
];

export default authenticationRoute;
