import turfBbox from '@turf/bbox';
import { computed, type ComputedRef, ref, type Ref, unref, type UnwrapRef } from 'vue';
import { type models, type routes, type Result, errors as sdkErrors } from '@withthegrid/amp-sdk';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@web-ui-root/helpers/local-storage/local-storage';
import {
  DEFAULT_GRID_PROGRESS_START_DATE_DAYS_OFFSET,
  THRESHOLD_LEVELS,
} from '@web-ui-root/helpers/constants/constants';
import { cloneDeep } from '@web-ui-root/helpers/object-helper';
import { point, featureCollection } from '@turf/helpers';
import type { Feature, Point } from 'geojson';
import { useSDK } from './sdk';
import { useBusHandler } from './bus-handler';

const { sdk } = useSDK();
const busHandler = useBusHandler();
// constants
const gridProgressStartDateStorageKey = 'gridProgressStartDate';

const t = {
  en: {
    errors: {
      payloadTooLarge: 'Your photo was too large to upload',
      conversionFailed: 'Your file could not be converted to a 300px wide jpeg',
    },
  },
  nl: {
    errors: {
      payloadTooLarge: 'Je foto is te groot om te uploaden',
      conversionFailed: 'Jouw bestand kon niet worden geconverteerd naar een 300px brede jpeg',
    },
  },
};

const leftPanelKeys = ['pinGroup', 'edge', 'pinGroupGrid', 'pinGrid'] as const;
type LeftPanelKeys = (typeof leftPanelKeys)[number];
const pascalCaseLeftPanelKeys = ['PinGroup', 'Edge', 'PinGroupGrid', 'PinGrid'] as const;
type PascalCaseLeftPanelKeys = (typeof pascalCaseLeftPanelKeys)[number];

// SDK types
type CancelToken = Result<never, never>['cancelToken'];

export type LeftPanelResponse<K extends LeftPanelKeys> = K extends 'pinGrid'
  ? routes.graph.getPinGrid.Response
  : K extends 'edge'
    ? routes.graph.getEdge.Response
    : K extends 'pinGroupGrid'
      ? routes.graph.getPinGroupGrid.Response
      : routes.graph.getPinGroup.Response;

export const isResponsePinGroup = (
  providedResponse: LeftPanelResponse<LeftPanelKeys>,
): providedResponse is LeftPanelResponse<'pinGroup'> =>
  'pinGroup' in providedResponse &&
  'pins' in providedResponse &&
  'edges' in providedResponse &&
  'device' in providedResponse &&
  'deviceType' in providedResponse &&
  'channelMapping' in providedResponse &&
  'thresholds' in providedResponse &&
  'grids' in providedResponse;

export const isResponseEdge = (
  providedResponse: LeftPanelResponse<LeftPanelKeys>,
): providedResponse is LeftPanelResponse<'edge'> =>
  'edge' in providedResponse &&
  'pins' in providedResponse &&
  'pinGroups' in providedResponse &&
  'thresholds' in providedResponse;

export const isResponsePinGrid = (
  providedResponse: LeftPanelResponse<LeftPanelKeys>,
): providedResponse is LeftPanelResponse<'pinGrid'> =>
  'grid' in providedResponse &&
  'pins' in providedResponse &&
  'pinGroups' in providedResponse &&
  'thresholds' in providedResponse;

export const isResponsePinGroupGrid = (
  providedResponse: LeftPanelResponse<LeftPanelKeys>,
): providedResponse is LeftPanelResponse<'pinGroupGrid'> =>
  'grid' in providedResponse &&
  'pinGroups' in providedResponse &&
  'lastReports' in providedResponse &&
  'notificationLevel' in providedResponse &&
  'photo' in providedResponse;

export function isUpdateControllerPinGroupGrid(
  providedUpdateController: LeftPanelUpdateController<LeftPanelKeys>,
): providedUpdateController is typeof sdk.routes.graph.updatePinGroupGrid {
  return providedUpdateController === sdk.routes.graph.updatePinGroupGrid;
}

export function isUpdateControllerPinGrid(
  providedUpdateController: LeftPanelUpdateController<LeftPanelKeys>,
): providedUpdateController is typeof sdk.routes.graph.updatePinGrid {
  return providedUpdateController === sdk.routes.graph.updatePinGrid;
}

export function isUpdateControllerEdge(
  providedUpdateController: LeftPanelUpdateController<LeftPanelKeys>,
): providedUpdateController is typeof sdk.routes.graph.updateEdge {
  return providedUpdateController === sdk.routes.graph.updateEdge;
}

export function isUpdateControllerPinGroup(
  providedUpdateController: LeftPanelUpdateController<LeftPanelKeys>,
): providedUpdateController is typeof sdk.routes.graph.updatePinGroup {
  return providedUpdateController === sdk.routes.graph.updatePinGroup;
}

export function isGeometryPoint(providedGeometry: {
  type: string;
  coordinates: number[] | [number, number];
}): providedGeometry is { type: 'Point'; coordinates: [number, number] } {
  return providedGeometry.type === 'Point';
}

export type LeftPanelUpdateController<K extends LeftPanelKeys> = K extends 'pinGroupGrid'
  ? typeof sdk.routes.graph.updatePinGroupGrid
  : K extends 'pinGrid'
    ? typeof sdk.routes.graph.updatePinGrid
    : K extends 'edge'
      ? typeof sdk.routes.graph.updateEdge
      : typeof sdk.routes.graph.updatePinGroup;

type LeftPanelObject<K extends LeftPanelKeys> = K extends 'pinGroupGrid'
  ? LeftPanelResponse<K>['grid']
  : K extends 'pinGrid'
    ? LeftPanelResponse<K>['grid']
    : K extends 'edge'
      ? LeftPanelResponse<K>['edge']
      : LeftPanelResponse<'pinGroup'>['pinGroup'];

type Quantity = models.quantity.Quantity;
type Thresholds = Record<(typeof THRESHOLD_LEVELS)[number], models.siNumber.SiNumber | null> & {
  issueDelayS: number | null;
  issueDelayCount: number | null;
};
type ThresholdsToUpdate = Array<{
  pinHashId: string;
  quantity: Quantity;
  thresholds: Thresholds;
}>;
type ThresholdsUpdateResults = Array<string>; // Array<hashId>

type QuantityThresholds = Thresholds & {
  hashId: string;
  pinHashId: string;
};
type StateThresholds = Array<{
  quantity: Quantity;
  value: QuantityThresholds;
}>;

// composable types
type PhotoState = {
  uploading: Ref<boolean>;
  removing: Ref<boolean>;
  uploadCancelToken: Ref<CancelToken | null>;
  removeCancelToken: Ref<CancelToken | null>;
};
type LeftPanelState = {
  typeKey: Ref<LeftPanelKeys | null>;
  hashId: Ref<string | null>;
  response: Ref<LeftPanelResponse<LeftPanelKeys> | null>;
  loading: Ref<boolean>;
  loadCancelToken: Ref<CancelToken | null>;
  windowName: Ref<string>;
  progressStartDate: Ref<Date>;
  isEditingProperties: Ref<boolean>;
  isIgnoreMapClickMode: Ref<boolean>;
  photo: PhotoState;
};

export type LeftPanelModel = {
  typeKey: LeftPanelState['typeKey'];
  loading: LeftPanelState['loading'];
  hashId: LeftPanelState['hashId'];
  response: LeftPanelState['response'];
  isEditingProperties: LeftPanelState['isEditingProperties'];
  isIgnoreMapClickMode: LeftPanelState['isIgnoreMapClickMode'];
  progressStartDate: LeftPanelState['progressStartDate'];
  windowName: LeftPanelState['windowName'];

  updateController: ComputedRef<LeftPanelUpdateController<LeftPanelKeys>>;
  objectTypeKeyAndHashId: ComputedRef<`${LeftPanelKeys}-${string}` | null>;
  object: ComputedRef<LeftPanelObject<LeftPanelKeys> | null>;
  pascalCaseTypeKey: ComputedRef<PascalCaseLeftPanelKeys | null>;
  title: ComputedRef<string | null>;
  hasPhoto: ComputedRef<boolean>;
  photo: ComputedRef<LeftPanelResponse<LeftPanelKeys>['photo'] | null>;

  changeProgressStartDate: (newDate: Date) => void;
  updatedExternally: (data: Partial<LeftPanelResponse<LeftPanelKeys>>) => void;

  load: (data: {
    typeKey: LeftPanelKeys;
    hashId: string;
    windowName?: string;
    zoomType?: number;
  }) => Promise<void>;
  updateThresholds: (data: {
    thresholdsToUpdate: ThresholdsToUpdate;
    thresholdsUpdateResults: ThresholdsUpdateResults;
  }) => void;
  clear: () => void;

  uploading: LeftPanelState['photo']['uploading'];
  removing: LeftPanelState['photo']['removing'];
  removePhoto: () => Promise<void>;
  uploadPhoto: (file: File, locale: models.locale.Locale) => Promise<void>;
  cancelRemove: () => void;
  cancelUpload: () => void;
};

const state: LeftPanelState = {
  typeKey: ref(null),
  hashId: ref(null),
  response: ref(null),
  loading: ref(false),
  loadCancelToken: ref(null),
  windowName: ref('home'),
  progressStartDate: ref(new Date()),
  isEditingProperties: ref(false),
  isIgnoreMapClickMode: ref(false),
  photo: {
    uploading: ref(false),
    removing: ref(false),
    uploadCancelToken: ref(null),
    removeCancelToken: ref(null),
  },
};

export const isObjectEdge = (
  providedObject: LeftPanelObject<LeftPanelKeys>,
): providedObject is LeftPanelObject<'edge'> =>
  state.typeKey.value === 'edge' &&
  'geometry' in providedObject &&
  !('symbolKey' in providedObject);

export const isObjectPinGroup = (
  providedObject: LeftPanelObject<LeftPanelKeys>,
): providedObject is LeftPanelObject<'pinGroup'> =>
  state.typeKey.value === 'pinGroup' &&
  'symbolKey' in providedObject &&
  'geometry' in providedObject;

export const isObjectPinGroupOrEdge = (
  providedObject: LeftPanelObject<LeftPanelKeys>,
): providedObject is LeftPanelObject<'pinGroup' | 'edge'> =>
  isObjectPinGroup(providedObject) || isObjectEdge(providedObject);

export const isObjectPinGroupGrid = (
  providedObject: LeftPanelObject<LeftPanelKeys>,
): providedObject is LeftPanelObject<'pinGroupGrid'> =>
  state.typeKey.value === 'pinGroupGrid' &&
  'grid' in providedObject &&
  'pins' in providedObject &&
  'pinGroups' in providedObject &&
  'thresholds' in providedObject;

export function useLeftPanel(): LeftPanelModel {
  const updateController = computed<LeftPanelUpdateController<LeftPanelKeys>>(() => {
    if (state.typeKey.value === 'pinGroup') {
      return sdk.routes.graph.updatePinGroup;
    }

    if (state.typeKey.value === 'pinGroupGrid') {
      return sdk.routes.graph.updatePinGroupGrid;
    }

    if (state.typeKey.value === 'edge') {
      return sdk.routes.graph.updateEdge;
    }

    return sdk.routes.graph.updatePinGrid;
  });

  const objectTypeKeyAndHashId = computed<`${LeftPanelKeys}-${string}` | null>(() => {
    if (state.typeKey.value === null) {
      return null;
    }

    return `${state.typeKey.value}-${state.hashId.value}`;
  });

  const object = computed<LeftPanelObject<LeftPanelKeys> | null>(() => {
    const response = unref(state.response);
    if (response === null) {
      return null;
    }

    if (state.typeKey.value === 'pinGrid') {
      return (response as LeftPanelResponse<'pinGrid'>).grid;
    }

    if (state.typeKey.value === 'pinGroupGrid') {
      return (response as LeftPanelResponse<'pinGroupGrid'>).grid;
    }

    if (state.typeKey.value === 'edge') {
      return (response as LeftPanelResponse<'edge'>).edge;
    }

    return (response as LeftPanelResponse<'pinGroup'>).pinGroup;
  });

  const pascalCaseTypeKey = computed<PascalCaseLeftPanelKeys | null>(() => {
    const key = unref(state.typeKey);
    if (key === null) {
      return null;
    }

    return `${key.charAt(0).toUpperCase()}${key.substring(1)}` as PascalCaseLeftPanelKeys;
  });

  const title = computed<string | null>(() => {
    if (object.value === null) {
      return null;
    }

    return object.value.name.toString();
  });

  const changeProgressStartDate = (value: Date) => {
    setLocalStorageItem(gridProgressStartDateStorageKey, value.toISOString());
    state.progressStartDate.value = value;
  };

  const loading = (data: {
    typeKey: UnwrapRef<LeftPanelState['typeKey']>;
    hashId: string;
    cancelToken: CancelToken | null;
  }): void => {
    state.typeKey.value = data.typeKey;
    state.hashId.value = data.hashId;
    state.loading.value = true;
    state.response.value = null;
    state.loadCancelToken.value = data.cancelToken;
    state.windowName.value = 'home';

    if (data.typeKey === 'pinGroupGrid') {
      const gridProgressStartDate = getLocalStorageItem(gridProgressStartDateStorageKey);
      let progressStartDate;
      if (typeof gridProgressStartDate === 'string') {
        const date = new Date(gridProgressStartDate);
        if (!Number.isNaN(date.valueOf())) {
          progressStartDate = date;
        }
      }
      if (progressStartDate === undefined) {
        progressStartDate = new Date();
        progressStartDate.setDate(
          progressStartDate.getDate() - DEFAULT_GRID_PROGRESS_START_DATE_DAYS_OFFSET,
        );
      }
      state.progressStartDate.value = progressStartDate;
    }
  };

  const loaded = (data: {
    response: LeftPanelResponse<LeftPanelKeys>;
    windowName?: UnwrapRef<LeftPanelState['windowName']>;
  }): void => {
    state.response.value = data.response;
    if (data.windowName !== undefined) {
      state.windowName.value = data.windowName;
    }
    state.loading.value = false;
  };

  const updatedExternally = (data: Partial<LeftPanelResponse<LeftPanelKeys>>): void => {
    // see left-panel.bak.ts
    Object.entries(data).forEach(([k, v]: [string, any]) => {
      if (state.response.value === null) {
        return;
      }
      if (k in state.response.value && v !== undefined) {
        (state.response.value as any)[k] = v;
      }
    });
  };

  const cleared = (): void => {
    state.typeKey.value = null;
    state.hashId.value = null;
    state.response.value = null;
    state.loading.value = false;
    state.windowName.value = 'home';
  };

  const clear = (): void => {
    if (state.loadCancelToken.value !== null) {
      state.loadCancelToken.value.cancel();
    }
    cleared();
  };

  const load = async (data: {
    typeKey: LeftPanelKeys;
    hashId: string;
    windowName?: string;
    zoomType?: number;
  }): Promise<void> => {
    if (state.loadCancelToken.value !== null) {
      state.loadCancelToken.value.cancel();
    }

    // required to get access to pascalCaseTypeKey
    loading({ cancelToken: null, typeKey: data.typeKey, hashId: data.hashId });

    const pascalCaseTypeKeyVal = unref(pascalCaseTypeKey);
    if (pascalCaseTypeKeyVal === null) {
      return;
    }

    const controller = sdk.routes.graph[`get${pascalCaseTypeKeyVal}`];

    let response;
    try {
      const result = controller({ params: { hashId: data.hashId } });
      loading({ cancelToken: result.cancelToken, typeKey: data.typeKey, hashId: data.hashId });
      response = await result.response;
    } catch (e) {
      if (!(e instanceof sdkErrors.CommsCanceled)) {
        console.log(e);
        clear();
        return;
      }
      return;
    }
    loaded({ response, windowName: data.windowName });

    const objectVal = unref(object);
    if (objectVal === null) {
      return;
    }

    if (
      isObjectPinGroupOrEdge(objectVal) &&
      data.zoomType !== undefined &&
      objectVal.geometry !== null
    ) {
      setTimeout(() => {
        busHandler.emit('fly', turfBbox(objectVal.geometry!), data.zoomType);
      }, 500);
    }
    if (state.typeKey.value === 'pinGroupGrid' && data.zoomType !== undefined) {
      const typedResponse = state.response.value as LeftPanelResponse<'pinGroupGrid'>;
      if (typedResponse.pinGroups.length > 0) {
        const geoJsonPoints: Feature<Point>[] = [];
        typedResponse.pinGroups.forEach((pinGroup) => {
          if (pinGroup.geometry !== null) {
            if (isGeometryPoint(pinGroup.geometry)) {
              geoJsonPoints.push(point(pinGroup.geometry.coordinates));
            }
          }
        });

        const geoJsonFeatureCollection = featureCollection(geoJsonPoints);
        const bounds = turfBbox(geoJsonFeatureCollection);
        setTimeout(() => {
          busHandler.emit('fitbounds', bounds);
        }, 500);
      }
    }
  };

  const updateThresholds = async ({
    thresholdsToUpdate,
    thresholdsUpdateResults,
  }: {
    thresholdsToUpdate: ThresholdsToUpdate;
    thresholdsUpdateResults: ThresholdsUpdateResults;
  }): Promise<void> => {
    if (state.response.value === null) {
      throw new Error('Response is not yet loaded');
    }
    let existingPins: string[];
    if (isResponseEdge(state.response.value)) {
      existingPins = state.response.value.pins.map((p) => p.hashId);
    } else if (isResponsePinGroup(state.response.value)) {
      existingPins = state.response.value.pins.map((p) => p.pin.hashId);
    } else {
      return;
    }

    const newThresholds: typeof state.response.value.thresholds = cloneDeep(
      state.response.value.thresholds,
    );
    thresholdsUpdateResults.forEach((r, index) => {
      const { quantity, pinHashId, thresholds } = thresholdsToUpdate[index];
      if (!existingPins.includes(pinHashId)) {
        return;
      }
      const existingIndex = newThresholds.findIndex(
        (thr) => thr.quantity.hashId === quantity.hashId && thr.value.pinHashId === pinHashId,
      );
      if (thresholds === null) {
        if (existingIndex > -1) {
          newThresholds.splice(existingIndex, 1);
        }
        return;
      }

      let threshold: StateThresholds[number];
      if (existingIndex === -1) {
        threshold = {
          quantity,
          value: {
            hashId: r,
            pinHashId,
            low: thresholds.low,
            high: thresholds.high,
            criticallyLow: thresholds.criticallyLow,
            criticallyHigh: thresholds.criticallyHigh,
            issueDelayS: thresholds.issueDelayS,
            issueDelayCount: thresholds.issueDelayCount,
          },
        };
        newThresholds.push(threshold);
      } else {
        threshold = newThresholds[existingIndex];
      }

      THRESHOLD_LEVELS.forEach((l) => {
        threshold.value[l] = cloneDeep(thresholds[l]);
      });
    });

    state.response.value.thresholds = newThresholds;
  };

  const hasPhoto = computed<boolean>(() => state.photo !== null);

  const photo = computed<string | null>(() => {
    if (state.response.value === null) {
      return null;
    }

    return state.response.value.photo;
  });

  const removePhoto = async (): Promise<void> => {
    if (state.hashId.value === null) {
      return;
    }

    state.photo.removing.value = true;

    const params = { hashId: state.hashId.value };
    const body = { photo: null };

    try {
      const result = updateController.value({ body, params });
      state.photo.removeCancelToken.value = result.cancelToken;
      await result.response;
    } catch (error) {
      state.photo.removing.value = false;
      if (error instanceof sdkErrors.CommsCanceled) {
        return;
      }
      busHandler.emit('commsError', error);
      return;
    }

    if (state.response.value !== null) {
      state.response.value.photo = body.photo;
    }
    state.photo.removing.value = false;
  };

  const uploadPhoto = async (file: File, locale: models.locale.Locale): Promise<void> => {
    if (state.hashId.value === null) {
      return;
    }

    state.photo.uploading.value = true;

    const reader = new FileReader();

    const params = { hashId: state.hashId.value };
    const body: Partial<{ photo: string }> = {};

    body.photo = await new Promise((resolve) => {
      reader.addEventListener('loadend', (event: ProgressEvent) => {
        if (reader.result === null) {
          console.log('Event', event);
          console.log('File', file);
          console.log('Reader error', reader.error);
          throw new Error('unable to read photo');
        }
        resolve(
          typeof reader.result === 'string'
            ? reader.result
            : new TextDecoder().decode(reader.result),
        );
      });
      reader.readAsDataURL(new Blob([file]));
    });

    try {
      const result = updateController.value({ body, params });
      state.photo.uploadCancelToken.value = result.cancelToken;
      await result.response;
    } catch (error) {
      state.photo.uploading.value = false;
      if (error instanceof sdkErrors.CommsCanceled) {
        return;
      }
      if (
        error instanceof sdkErrors.CommsResponse &&
        typeof error.data === 'object' &&
        error.data !== null
      ) {
        if (error.data.key === 'conversion_failed') {
          busHandler.emit('error', t[locale].errors.conversionFailed);
          return;
        }
        if (error.data.key === 'payload_too_large') {
          // error should be shown by App.vue
          return;
        }
      } else if (error instanceof sdkErrors.CommsResponse && error.status === 413) {
        // caused by nginx client_max_body_size
        // error should be shown by App.vue
        return;
      }
      busHandler.emit('commsError', error);
      return;
    }

    if (body.photo !== undefined && state.response.value !== null) {
      state.response.value.photo = body.photo;
      state.photo.uploading.value = false;
    }
  };

  const cancelRemove = (): void => {
    if (state.photo.removeCancelToken.value !== null) {
      state.photo.removeCancelToken.value.cancel();
      state.photo.removeCancelToken.value = null;
    }
  };

  const cancelUpload = (): void => {
    if (state.photo.uploadCancelToken.value !== null) {
      state.photo.uploadCancelToken.value.cancel();
      state.photo.uploadCancelToken.value = null;
    }
  };

  return {
    typeKey: state.typeKey,
    loading: state.loading,
    hashId: state.hashId,
    response: state.response,
    isIgnoreMapClickMode: state.isIgnoreMapClickMode,
    isEditingProperties: state.isEditingProperties,
    progressStartDate: state.progressStartDate,
    windowName: state.windowName,

    uploading: state.photo.uploading,
    removing: state.photo.removing,

    updateController,
    objectTypeKeyAndHashId,
    object,
    pascalCaseTypeKey,
    title,
    hasPhoto,
    photo,

    changeProgressStartDate,
    updatedExternally,

    load,
    updateThresholds,
    clear,
    removePhoto,
    uploadPhoto,
    cancelRemove,
    cancelUpload,
  };
}
