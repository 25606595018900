export const SI_PREFIXES = [
  'y',
  'z',
  'a',
  'f',
  'p',
  'n',
  'µ',
  'm',
  '',
  'k',
  'M',
  'G',
  'T',
  'P',
  'E',
  'Z',
  'Y',
];

export const SI_PREFIXES_BASE_0_INDEX = SI_PREFIXES.indexOf('');

export const DEFAULT_GRID_PROGRESS_START_DATE_DAYS_OFFSET = 30;

export const SECOND_IN_MS = 1000;

export const MINUTE_IN_MS = 60 * 1000;

export const HOUR_IN_MS = 60 * 60 * 1000;

export const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const ONE_MINUTE_IN_S = 60;

export const ONE_HOUR_IN_S = 60 * 60;

export const ONE_DAY_IN_S = 24 * 60 * 60;

// Order from low to high!
export const THRESHOLD_LEVELS = ['criticallyLow', 'low', 'high', 'criticallyHigh'] as const;

export const SERIOUS_THRESHOLD_COLOR = '#ffc107';
export const CRITICAL_THRESHOLD_COLOR = '#f44336';

export const GROUP_PIN_COLORS = [
  '#483589',
  '#9F8BF9',
  '#049F79',
  '#E69842',
  '#1C756E',
  '#792517',
  '#83E74B',
  '#D5A403',
  '#48E493',
  '#5C4586',
  '#003EAE',
  '#0CA42B',
  '#028388',
  '#1ABD31',
  '#2295FE',
  '#96704F',
  '#536364',
  '#703AA1',
  '#8930EA',
  '#2CACD8',
] as const;
