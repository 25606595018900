import { type App } from 'vue';
import { useSDK } from '@web-ui-root/composables/sdk';

/**
 * A simple plugin wrapper for the SDK composable to keep the option api defined components working
 */
export default {
  install: (app: App<unknown>): void => {
    const { sdk } = useSDK();

    // for option syntax: this.$plugin
    app.config.globalProperties.$sdk = sdk;
    // for <script setup> syntax: inject('$plugin')
    app.provide('$sdk', sdk);
  },
};
