<template>
  <v-tooltip
    v-bind="direction"
    :open-delay="300"
  >
    <template #activator="{ props: tooltipProps }">
      <component
        :is="tag"
        :id="uuid"
        class="overflow"
        :class="classes"
        v-bind="hasOverflowed ? tooltipProps : null"
      >
        <slot />
      </component>
    </template>

    <!-- We use a div here and not a span to wrap the text (using the not-overflow class) -->
    <div class="not-overflow">
      {{ text }}
    </div>
  </v-tooltip>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted, computed } from 'vue';
import { useUUID } from '../composables/uuid';

const isTrue = (v: boolean) => v === true;

// todo: can't figure out how to defineProps withDefaults combined with the tag validator
const props = defineProps({
  top: {
    type: Boolean,
    default: false,
  },
  right: {
    type: Boolean,
    default: false,
  },
  bottom: {
    type: Boolean,
    default: false,
  },
  left: {
    type: Boolean,
    default: false,
  },
  tag: {
    type: String,
    default: 'span',
    validator: (value: string) => value === 'span' || value === 'div',
  },
  classes: {
    type: String,
    default: '',
  },
});

const { uuid } = useUUID();
uuid.toString();

const loaded = ref(false);

const text = computed(() => {
  const target: HTMLElement | null = document.getElementById(uuid.value);
  if (loaded.value && target !== null) {
    return target.innerText;
  }

  return '';
});
const hasOverflowed = computed(() => {
  const target: HTMLElement | null = document.getElementById(uuid.value);
  if (
    target !== null &&
    target.offsetWidth === 0 &&
    target.scrollWidth === 0 &&
    text.value.length > 30
  ) {
    return loaded.value && text.value !== undefined;
  }

  return loaded.value && target !== null && target.offsetWidth < target.scrollWidth;
});
const direction = computed(() => {
  const allFalse = !props.top && !props.right && !props.bottom && !props.left;

  // binds directions as given or defaults to top if none is provided
  return {
    top: allFalse ? true : props.top,
    right: props.right,
    bottom: props.bottom,
    left: props.left,
  };
});

if ([props.top, props.right, props.bottom, props.left].filter(isTrue).length > 1) {
  // developer error
  console.warn('TextOverflow: Only one side is supported for the tooltip');
}

onMounted(() => {
  nextTick(() => {
    loaded.value = true;
  });
});
</script>

<style scoped>
.overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  visibility: visible;
}

.not-overflow {
  overflow-wrap: break-word;
  white-space: pre-line;
  max-width: 60vw;
}
</style>
